import { Button, Typography } from "@material-ui/core"
import { useMemo } from "react"
import { useParams } from "react-router-dom"
import useApxorClient from "../../../utils/use-apxor-client"
import useIntegration from "../../Auth/integration-experience/hooks/use-integration"
import { useTrackedState } from "../../IntegrationExperience/store"
import HStack from "../../ReusableComponents/HStack"
import Loading from "../../ReusableComponents/Loading"
import ModalPopup from "../../ReusableComponents/ModalPopup"
import VStack from "../../ReusableComponents/VStack"

export default function SDKMonitoringDialog({
  open,
  onClose,
  selectedAppId,
  enabled,
  setMonitoring,
  setSnackBarMessage,
}) {
  const { logEvent } = useApxorClient()
  const { updateMonitoringStatus } = useIntegration()
  const { app_loading } = useTrackedState()

  const { appId } = useParams()

  const app_id = useMemo(
    () => (selectedAppId ? selectedAppId : appId),
    [appId, selectedAppId],
  )

  return (
    <ModalPopup
      open={open}
      onClose={onClose}
      maxWidth="400px"
      title="SDK Monitoring?"
    >
      <VStack spacing="16px">
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#002845",
          }}
        >
          Are you sure want to <b>{enabled ? "DISABLE" : "ENABLE"}</b> SDK
          monitoring?
        </Typography>
        <HStack
          align="center"
          justify="end"
          spacing="30px"
          style={{
            marginTop: "16px",
          }}
        >
          <Button
            variant="text"
            color="default"
            size="large"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={app_loading}
            onClick={async () => {
              if (await updateMonitoringStatus(app_id, !enabled))
                setMonitoring(!enabled)
              onClose()
              setSnackBarMessage(
                `SDK Monitoring ${
                  enabled ? "disabled" : "enabled"
                } successfully!`,
              )
              logEvent("LP_SDK_Monitoring_Toggled", {
                cta_type: enabled ? "enable" : "disable",
              })
            }}
          >
            {app_loading ? (
              <Loading
                size={28}
                color="#FFFFFF"
              />
            ) : (
              <>{enabled ? "Disable" : "Enable"}</>
            )}
          </Button>
        </HStack>
      </VStack>
    </ModalPopup>
  )
}
