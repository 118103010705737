export const APP_PLATFORMS = {
  android: "android",
  ios: "ios",
  web: "web",
  omni: "omni",
}

export const APXOR_ROLES = {
  APX_ADMIN: "APX_ADMIN",
  REGULAR_USER: "USER",
}

export const DASHBOARD_ENDPOINTS = {
  ALERTS: "alerts",
  APPS: "apps",
  APPROVAL: "approve",
  AUDIENCE: "audience",
  CONTEXTS: "contexts",
  CUSTOM_GROUPING: "custom-grouping",
  CUSTOM_REPORTS: "custom-reports",
  DASHBOARDS: "dashboards",
  DOWNLOADS: "downloads",
  DROP_OFF: "drop-off",
  ENGAGEMENT_REPORT: "reports/key-metrics",
  EVENT_ANALYSIS: "event-analysis",
  FUNNELS: "funnels",
  HEALTH: "health",
  INCIDENTS: "incidents",
  INTEGRATION: "integration",
  ISSUE_DETAILS: "issues",
  LOGIN: "login",
  MESSAGES: "messages",
  MESSAGES2: "walkthroughs",
  OVERVIEW: "overview",
  PATH: "path",
  PREPROCESS: "preprocess",
  PROFILE: "profile",
  PUSH: "push",
  REPORTS: "reports",
  RESET_PASSWORD: "reset-password",
  RETENTION: "retention",
  SEGMENTS: "segments",
  SETTINGS: "settings",
  SETTINGS_DATA: "settings/downloads",
  SETTINGS_IAM: "settings/iam",
  SETTINGS_SDK: "settings/sdk",
  SETTINGS_SESSIONS: "settings/sessions",
  SETTINGS_TEST_DEVICES: "settings/test-devices",
  SETTINGS_EVENTS_MANAGEMENT: "settings/events",
  SETTINGS_USERS: "settings/users",
  SETTINGS_MANAGE_PLAN: "settings/manage-plan",
  SIGN_UP: "sign_up",
  SURVEYS: "surveys",
  UNAUTHORISED: "unauthorised",
  UNINSTALL_REPORT: "reports/day0-analysis",
  UNINSTALLS: "uninstalls",
  USER: "user",
  USER_EXPLORER: "user-explorer",
  USERS: "users",
}

export const DATE_FILTERS_INVALID_PATHS = [
  //TODO: more will come
  DASHBOARD_ENDPOINTS.DOWNLOADS,
  DASHBOARD_ENDPOINTS.SETTINGS_USERS,
  DASHBOARD_ENDPOINTS.SETTINGS,
  DASHBOARD_ENDPOINTS.ENGAGEMENT_REPORT,
  DASHBOARD_ENDPOINTS.MESSAGES,
  DASHBOARD_ENDPOINTS.MESSAGES2,
  DASHBOARD_ENDPOINTS.SEGMENTS + "$",
  DASHBOARD_ENDPOINTS.SETTINGS_TEST_DEVICES,
  DASHBOARD_ENDPOINTS.DASHBOARDS + "$",
  DASHBOARD_ENDPOINTS.CONTEXTS,
  DASHBOARD_ENDPOINTS.SURVEYS + "$",
  DASHBOARD_ENDPOINTS.PREPROCESS,
  DASHBOARD_ENDPOINTS.PUSH,
  DASHBOARD_ENDPOINTS.APPROVAL,
]

export const DATE_FILTERS_DISABLED_PATHS = [
  //TODO: more will come
  //`${DASHBOARD_ENDPOINTS.SEGMENTS}/new`
]

export const MODES_ENUM = {
  PRODUCTION: "Production",
  NON_PRODUCTION: "Non-Production",
}

export const USER_GROUP_ENUM = {
  INSTALL_USERS: "install_users",
  INACTIVE_USERS: "inactive_users",
  UNHAPPY_USERS: "unhappy_users",
  UNINSTALL_USERS: "uninstall_users",
  ACTIVE_USERS: "active_users",
  HAPPY_USERS: "happy_users",
  ALL_USERS: "all_users",
  REINSTALL_USERS: "reinstall_users",
  D0_UNINSTALL_USERS: "d0_uninstall_users",
  D1_UNINSTALL_USERS: "d1_uninstall_users",
  D2TO7_UNINSTALL_USERS: "d2to7_uninstall_users",
  D8T14_UNINSTALL_USERS: "d8to14_uninstall_users",
  D15T30_UNINSTALL_USERS: "d15to30_uninstall_users",
  CUSTOM_GROUP_USERS: "custom_group_users",
  CUSTOM_GROUP_USERS_WITH_EVENTS: "custom_group_users_with_events",
  RETAINED_USERS: "retained_users",
  RETURNING_USERS: "returning_users",
}

export const USER_GROUPS = [
  {
    name: "All Users",
    value: USER_GROUP_ENUM.ALL_USERS,
  },
  {
    name: "New Installs",
    value: USER_GROUP_ENUM.INSTALL_USERS,
  },
  {
    name: "Returning Users",
    value: USER_GROUP_ENUM.RETURNING_USERS,
  },
]

/**
 * Keys for user group distributions
 * @type {{app_versions: string, devices: string, os_versions: string, location: string, language: string, acquisition_sources: string, custom_keys: string}}
 */
export const USER_GROUP_FILTERS_CONSTANTS = {
  app_versions: "app_versions",
  devices: "devices",
  os_versions: "os_versions",
  locations: "locations",
  languages: "languages",
  acquisition_sources: "acquisition_sources",
  custom_keys: "custom_keys",
}

export const OTHERS_KEY = "others"
export const UNKNOWN_KEY = "unknown"
export const ON_APP_STATE_CHANGE = "ON_APP_STATE_CHANGE"

export const APX_FEATURES = {
  APX_SEGMENT_DOWNLOAD: "APX_SEGMENT_DOWNLOAD",
  APX_DIGEST_REPORTS: "APX_DIGEST_REPORTS",
  APX_CLIENT_EVENTS: "APX_CLIENT_EVENTS",
  APX_NORMALIZED_TIME_SPENT: "APX_NORMALIZED_TIME_SPENT",
}

export const LOGICAL_OPERATORS = {
  GT: "GT",
  GTE: "GTE",
  LT: "LT",
  LTE: "LTE",
  EQ: "EQ",
  NEQ: "NEQ",
}

export const conditionalUtterance = (operator) => {
  const { GT, GTE, EQ, NEQ, LT, LTE, R = "R" } = LOGICAL_OPERATORS

  switch (operator) {
    case GT:
      return " more than "
    case GTE:
      return " at least "
    case LT:
      return " less than "
    case LTE:
      return " at most "
    case EQ:
      return " exactly "
    case NEQ:
      return " not "
    case R:
      return " matches with "
    default:
      return ""
  }
}

export const CONTAINS_OPERATORS = {
  CONTAINS: "CONTAINS",
  NOTCONTAINS: "NOTCONTAINS",
}

export const Operators = [
  { label: ">", value: LOGICAL_OPERATORS.GT },
  { label: "≥", value: LOGICAL_OPERATORS.GTE },
  { label: "<", value: LOGICAL_OPERATORS.LT },
  { label: "≤", value: LOGICAL_OPERATORS.LTE },
  { label: "=", value: LOGICAL_OPERATORS.EQ },
  { label: "!=", value: LOGICAL_OPERATORS.NEQ },
]

export const ContainsOperators = [
  ...Operators,
  // {label: "≠", value: LOGICAL_OPERATORS.NEQ, lingual: "is not"},
  { label: "∋", value: CONTAINS_OPERATORS.CONTAINS, lingual: "contains" },
  {
    label: "∌",
    value: CONTAINS_OPERATORS.NOTCONTAINS,
    lingual: "does not contain",
  },
]

export const CONDTIONAL_OPERATORS = {
  AND: "AND",
  OR: "OR",
}

export const COUNT_TYPE_ENUM = {
  event: "event",
  sessions: "sessions",
  users: "users",
  session_length: "session_length",
  all: "all",
  impressions: "impressions",
}

export const MESSAGE_EVENTS = {
  //FIXME: may vary, so need API
  INLINE_SHOWN: "inline_shown",
  INAPP_SHOWN: "inapp_shown",
  INLINE_DISMISSED: "inline_dismissed",
  INAPP_DISMISSED: "inapp_dismissed",
}

export const WALKTHROUGH_TYPE_ENUM = {
  WALKTHROUGH: "walkthrough",
  ONBOARDING_WALKTHROUGH: "onboarding",
}

export const ACTIONS_ENUM = {
  WALKTHROUGH: "walkthroughs",
  SURVEY: "surveys",
  PUSH: "PUSH",
}

export const GRAPH_DEFAULTS = {
  switchData: [
    {
      name: "7 Days",
      value: "7 Days",
    },
    {
      name: "14 Days",
      value: "14 Days",
    },
    {
      name: "30 Days",
      value: "30 Days",
    },
    {
      name: "Weeks",
      value: "Week",
    },
    {
      name: "Months",
      value: "Month",
    },
  ],
  title: "Retention analysis",
  dateDisplayFormat: "MMM DD YYYY",
  switchValue: "7 Days",
  dataDisplayFormat: "percent",
}

/**
 *
 * @type {{ASC: number, DESC: number}}
 */
export const SortUtils = {
  ASC: +1,
  DESC: -1,
}

export const EVENT_TYPE_ENUM = {
  NAVIGATION_ITEM: "NavigationItem",
  EVENT_ITEM: "EventItem",
  APP_EVENT_ITEM: "AppEventItem",
  ISSUE_ITEM: "IssueItem",
}

export const APXOR_APP_DOWNLOAD_LINK =
  "https://storage.googleapis.com/apx-artifactory/ApxorHelperApp.apk"

export const FEATURE_KEY_ENUM = {
  COHORTS: "APX_COHORTS",
  DYNAMIC_COHORTS: "DYNAMIC_COHORTS",
  CUSTOM_REPORTS: "APX_CUSTOM_REPORTS",
  DASHBOARD: "APX_DASHBOARD",
  DOWNLOADS: "APX_DOWNLOADS",
  EVENT_ANALYSIS: "APX_EVENT_ANALYSIS",
  FUNNELS: "APX_FUNNELS",
  WALKTHROUGHS: "APX_WALKTHROUGHS",
  PATH: "APX_PATH",
  PREPROCESS: "APX_PREPROCESS",
  PUSH: "APX_PUSH",
  RETENTION: "APX_RETENTION",
  SEGMENTS: "APX_SEGMENTS",
  SETTINGS: "APX_SETTINGS",
  SETTINGS_DATA: "APX_SETTINGS_DATA",
  SETTINGS_GENERAL: "APX_SETTINGS_GENERAL",
  SETTINGS_IAM: "APX_SETTINGS_IAM",
  SETTINGS_SDK: "APX_SETTINGS_SDK",
  SETTINGS_SESSIONS: "APX_SETTINGS_SESSIONS",
  SETTINGS_TEST_DEVICES: "APX_SETTINGS_TEST_DEVICES",
  SETTINGS_MANAGE_PLAN: "APX_SETTINGS_MANAGE_PLAN",
  SETTINGS_USERS: "APX_SETTINGS_USERS",
  SURVEYS: "APX_SURVEYS",
  UNINSTALLS: "APX_UNINSTALLS",
  USER_EXPLORER: "APX_USER_EXPLORER",
  ANALYTICS: "APX_ANALYTICS",
  ACTIONS: "APX_ACTIONS",
  AB_ANALYSIS: "APX_AB",
  GO_INGESTOR: "APX_GO_INGESTOR",
  INT_EXP: "APX_INT_EXP",
  GLOBAL_THEMES: "APX_GLOBAL_THEMES",
  CUSTOM_FONTS: "APX_CUSTOM_FONTS",
}

export const ANALYTICS = [
  FEATURE_KEY_ENUM.CUSTOM_REPORTS,
  FEATURE_KEY_ENUM.EVENT_ANALYSIS,
  FEATURE_KEY_ENUM.FUNNELS,
  FEATURE_KEY_ENUM.PATH,
  FEATURE_KEY_ENUM.PREPROCESS,
  FEATURE_KEY_ENUM.RETENTION,
  FEATURE_KEY_ENUM.SEGMENTS,
  FEATURE_KEY_ENUM.UNINSTALLS,
  FEATURE_KEY_ENUM.USER_EXPLORER,
  FEATURE_KEY_ENUM.GO_INGESTOR,
]
export const ACTIONS = [
  FEATURE_KEY_ENUM.COHORTS,
  FEATURE_KEY_ENUM.DASHBOARD,
  FEATURE_KEY_ENUM.WALKTHROUGHS,
  FEATURE_KEY_ENUM.SURVEYS,
  FEATURE_KEY_ENUM.PUSH,
  FEATURE_KEY_ENUM.AB_ANALYSIS,
]

/**
 * Apxor SDK Event logging constants
 */
export const APXOR_SDK_CATEGORY_CONSTANTS = {
  RETENTION: "Retention",
  FUNNELS: "Funnels",
  BEHAVIOUR_CORRELATION_FRAMEWORK: "Behaviour Correlation Framework",
  COHORT: "Cohort",
  DYNAMIC_COHORTS: "Dynamic Cohorts",
  VERSION: "Version",
  EVENT: "Event Analysis",
  DASHBOARD: "Dashboard",
  NAVIGATION: "Navigation",
  UNINSTALLS: "Uninstalls",
  USER_SEGMENTS: "User Segments",
  USER_EXPLORER: "User Explorer",
  CAMPAIGNS: "Campaigns",
  OPERATOR: "Operator",
  SEGMENTS: "Segments",
  CUSTOM_REPORTS: "Custom Reports",
}

export const APXOR_SDK_CAMPAIGN_PRIORITY_DEFAULTS = {
  MIN: -1 * Math.pow(2, 31),
  MAX: Math.pow(2, 31),
}

export const PRIORITY_FEATURE_TYPE_ENUM = {
  walkthrough: "campaign",
  walkthroughs: "campaign",
  survey: "survey",
  "a/b": "A/B Test",
}

/**
 * @typedef {Object} BorderTypesEnum
 * @property {Object} LIGHT - Light border
 * @property {string} LIGHT.variant - Light border variant
 * @property {string} LIGHT.value - Light border value
 * @property {Object} DARK - Dark border
 * @property {string} DARK.variant - Dark border variant
 * @property {string} DARK.value - Dark border value
 *
 * @enum {BorderTypesEnum}
 * @readonly
 * @description Enum for the different types of border
 */
export const BORDER_TYPES_ENUM = Object.freeze({
  LIGHT: {
    variant: "light",
    value: "rgba(0, 0, 0, 0.08)",
  },
  DARK: {
    variant: "dark",
    value: "rgba(0, 0, 0, 0.12)",
  },
})
