import { Button, Grid, Tab, Tabs } from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import Avatar from "@material-ui/core/Avatar"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import classNames from "classnames"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Link, useHistory, useLocation, useParams } from "react-router-dom"
import { ApxorAppInfo, isExperienceDashboard } from "../../../config"
import { FEATURE_KEY_ENUM } from "../../../constants"
import { DASHBOARD_ENDPOINTS } from "../../../constants/Endpoints"
import { useAuth } from "../../../contexts/AuthContext"
import {
  capitalizeEachWord,
  getDefaultPage,
  isAccessable,
  notOnDashboard,
} from "../../../utils"
import ExperienceDemoFinishStep from "../../Dashboard/components/dashboard/components/sales-sandbox/ExperienceDemoFinishStep"
import BootstrapTooltip from "../../ReusableComponents/BootstrapTooltip"
import ColumnDiv from "../../ReusableComponents/ColumnDiv"
import CustomMaterialUIDialog from "../../ReusableComponents/CustomMaterialUIDialog"
import Dialog from "../../ReusableComponents/Dialog"
import RowDiv from "../../ReusableComponents/RowDiv"
import { SET_TAB } from "../reducer"
import { useTracked } from "../store"
import { apxTheme, styles } from "../styling/CustomCSS"

const COMMON = [
  "SDK",
  "Test Devices",
  "Downloads",
  "IAM",
  "General",
  "Events Management",
]

const INSIGHTS = [
  "Retention",
  "Funnels",
  "Behaviour Correlation Framework",
  "Event Analysis",
  "Uninstalls",
  "Segments",
  "User Explorer",
  "Custom Reports",
  "Paths",
  ...COMMON,
]

const NUDGES = ["Campaigns", "Surveys", ...COMMON]

const fetchHeadingFromLocation = (location) => {
  const HEADING_CONSTANTS = {
    [DASHBOARD_ENDPOINTS.RETENTION]: "Retention",
    [DASHBOARD_ENDPOINTS.FUNNELS]: "Funnels",
    [DASHBOARD_ENDPOINTS.PREPROCESS]: "Behaviour Correlation Framework",
    [DASHBOARD_ENDPOINTS.EVENT_ANALYSIS]: "Event Analysis",
    [DASHBOARD_ENDPOINTS.DASHBOARDS]: "Overview",
    [DASHBOARD_ENDPOINTS.UNINSTALLS]: "Uninstalls",
    [DASHBOARD_ENDPOINTS.SEGMENTS]: "Segments",
    [DASHBOARD_ENDPOINTS.USER_EXPLORER]: "User Explorer",
    [DASHBOARD_ENDPOINTS.COHORTS]: "Cohorts",
    [DASHBOARD_ENDPOINTS.PUSH]: "Push Notifications",
    [DASHBOARD_ENDPOINTS.WALKTHROUGHS]: "Campaigns",
    [DASHBOARD_ENDPOINTS.SURVEYS]: "Surveys",
    [DASHBOARD_ENDPOINTS.CUSTOM_REPORTS]: "Custom Reports",
    [DASHBOARD_ENDPOINTS.PATH]: "Paths",
    [DASHBOARD_ENDPOINTS.SETTINGS_SDK]: "SDK",
    [DASHBOARD_ENDPOINTS.SETTINGS_TEST_DEVICES]: "Test Devices",
    [DASHBOARD_ENDPOINTS.SETTINGS_EM]: "Events Management",
    [DASHBOARD_ENDPOINTS.SETTINGS_DATA]: "Downloads",
    [DASHBOARD_ENDPOINTS.SETTINGS_IAM]: "IAM",
    [DASHBOARD_ENDPOINTS.SETTINGS_GENERAL]: "General",
    "": "Overview",
  }
  let data = location.pathname.split(/\/apps\/[^/]+\//g)[1]
  if (data && data.indexOf("/") !== -1) {
    data = data.split("/")[0]
  }
  return HEADING_CONSTANTS[data] || "Overview"
}

export const UserMenu = ({ setContactUsDialogOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [showAccessCodeDialog, setShowAccessCodeDialog] = useState(false)

  function closeAccessCodeDialog() {
    setShowAccessCodeDialog(false)
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const auth = useAuth()
  const { user } = auth
  const { orgId } = useParams()

  const demoDate =
    isExperienceDashboard() && user?.demo_period_end_date
      ? Math.ceil(
          moment(user?.demo_period_end_date).diff(
            moment(new Date().toUTCString()),
            "days",
            true,
          ),
        )
      : 0
  const demoPeriodDaysLeft =
    isExperienceDashboard() && user?.demo_period_end_date
      ? demoDate.toString().split("")
      : []

  return (
    <>
      {user?.email && user?.email !== "" ? (
        <>
          {isExperienceDashboard() &&
            !isNaN(demoDate) &&
            demoPeriodDaysLeft.every((num) => !isNaN(+num)) && (
              <RowDiv
                center
                styles={{ gap: "30px" }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  style={{
                    border: "1.5px solid",
                    fontWeight: "700",
                  }}
                  onClick={() => setContactUsDialogOpen(true)}
                >
                  <Typography
                    color="primary"
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    Schedule a Demo
                  </Typography>
                </Button>
                <RowDiv
                  center
                  styles={{
                    marginRight: "48px",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                    }}
                  >
                    {demoPeriodDaysLeft.map((num, idx) => (
                      <span
                        style={{
                          backgroundColor: "rgba(249, 217, 199, 1)",
                          borderRadius: "2px",
                          padding: "4px 10px",
                          marginRight:
                            idx === demoPeriodDaysLeft.length - 1 ? "0" : "5px",
                        }}
                        key={num}
                      >
                        {num}
                      </span>
                    ))}{" "}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      marginLeft: "10px",
                    }}
                  >
                    days demo period left
                  </Typography>
                </RowDiv>
              </RowDiv>
            )}
          <Avatar
            style={{
              border: "2px solid",
              borderColor: apxTheme.palette.primary.main,
              backgroundColor: "transparent",
            }}
          >
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "16px",
              }}
            >
              {capitalizeEachWord(user?.[user?.name ? "name" : "email"])
                ?.split(" ")
                .map((name) => name[0])
                .join("")}
            </Typography>
          </Avatar>
          <IconButton
            size="small"
            aria-label="User Menu"
            aria-owns={open ? "long-menu" : null}
            aria-haspopup="true"
            onClick={handleClick}
            style={{
              marginRight: "26px",
              color: apxTheme.palette.text.primary,
            }}
          >
            {anchorEl ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                padding: "20px",
                minWidth: "250px",
                marginTop: "40px",
                backgroundColor: "#002845",
              },
            }}
            MenuListProps={{
              style: {
                padding: "0px",
                backgroundColor: "#002845",
              },
            }}
          >
            <Grid container>
              <Grid
                item
                xs={3}
              >
                <Avatar>
                  {capitalizeEachWord(user?.[user?.name ? "name" : "email"])
                    ?.split(" ")
                    .map((name) => name[0])
                    .join("")}
                </Avatar>
              </Grid>
              <Grid
                item
                xs={9}
              >
                <ColumnDiv
                  styles={{
                    backgroundColor: "#002845",
                  }}
                >
                  <ColumnDiv
                    styles={{
                      gap: "1px",
                      marginBottom:
                        (isExperienceDashboard() &&
                          user?.demo_period_end_date) ||
                        !isExperienceDashboard()
                          ? "8px"
                          : "20px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#fff",
                        fontSize: "14px",
                        fontWeight: "700",
                      }}
                    >
                      {user?.name}
                    </Typography>
                    <Typography
                      style={{
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "500",
                        opacity: "0.6",
                        overflowX: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {user?.email}
                    </Typography>
                  </ColumnDiv>
                  {((isExperienceDashboard() && user?.demo_period_end_date) ||
                    !isExperienceDashboard()) && (
                    <RowDiv
                      center
                      styles={{
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <ColumnDiv
                        style={{
                          borderRadius: "2px",
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                          backgroundColor: apxTheme.palette.primary.main,
                          padding: "16px 6px",
                          minWidth: "40px",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#fff",
                            fontSize: "15px",
                            fontWeight: "700",
                            textAlign: "center",
                          }}
                        >
                          {isExperienceDashboard()
                            ? demoPeriodDaysLeft.toString().replace(/,/g, "")
                            : "12"}
                        </Typography>
                      </ColumnDiv>
                      <ColumnDiv
                        styles={{
                          padding: "6px",
                          width: "100%",
                          gap: "4px",
                          backgroundColor: "rgba(255, 127, 51, 0.2)",
                          borderRadius: "2px",
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          alignItems: "start",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#fff",
                            fontSize: "12px",
                            fontWeight: "600",
                          }}
                        >
                          Days {isExperienceDashboard() ? "Demo" : "Trail"} Left
                        </Typography>
                        <a
                          href="https://www.apxor.com/sign-up"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            variant="text"
                            color="primary"
                            style={{
                              padding: "0px",
                              fontWeight: "700",
                              fontSize: "12px",
                              marginLeft: "1px",
                            }}
                          >
                            {isExperienceDashboard()
                              ? "Integrate My App"
                              : "Upgrade"}
                          </Button>
                        </a>
                      </ColumnDiv>
                    </RowDiv>
                  )}
                  <ColumnDiv styles={{ gap: "22px" }}>
                    <Link
                      to={`/orgs/${orgId}/apps`}
                      onClick={handleClose}
                    >
                      <Typography
                        style={{
                          color: "#fff",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Home
                      </Typography>
                    </Link>
                    {!isExperienceDashboard() && (
                      <Link
                        to="/profile"
                        onClick={handleClose}
                      >
                        <Typography
                          style={{
                            color: "#fff",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          My Profile
                        </Typography>
                      </Link>
                    )}
                    {isExperienceDashboard() && (
                      <Typography
                        style={{
                          color: "#fff",
                          fontSize: "14px",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowAccessCodeDialog(true)
                          handleClose()
                        }}
                      >
                        App Access Code
                      </Typography>
                    )}
                    <Link
                      to="/logout"
                      onClick={handleClose}
                      style={{
                        marginBottom: "20px",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#fff",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Logout
                      </Typography>
                    </Link>
                  </ColumnDiv>
                </ColumnDiv>
              </Grid>
            </Grid>
          </Menu>
        </>
      ) : (
        <Link
          to="/login"
          className="dropdown-toggle"
          style={{ padding: "0 10px" }}
        >
          <Typography
            variant="h6"
            color="inherit"
          >
            Login
          </Typography>
        </Link>
      )}
      {user?.email && user?.email !== "" && showAccessCodeDialog && (
        <CustomMaterialUIDialog
          onClose={closeAccessCodeDialog}
          onDialogClose={closeAccessCodeDialog}
          openFlag={showAccessCodeDialog}
          maxWidth="md"
          dialogContent={
            <ExperienceDemoFinishStep
              close_dialog={closeAccessCodeDialog}
              just_display_access_code={true}
            />
          }
          dialogContentStyles={{
            padding: "50px",
            paddingTop: "0px",
          }}
          dialogTitleStyles={{
            ...(isExperienceDashboard() && { padding: "26px" }),
          }}
        />
      )}
    </>
  )
}

export const Logo = () => {
  const { name, logo } = ApxorAppInfo
  const { orgId } = useParams()
  return (
    <Link
      to={`/orgs/${orgId}/apps`}
      style={{ margin: "0 10px", marginTop: "6px" }}
    >
      <img
        alt={name}
        src={logo}
      />
    </Link>
  )
}

const NO_CONNECTION_MESSAGE = "No internet connection..!"

const AppHeaderWrapper = ({
  classes,
  appState,
  pendingAppAPI = false,
  isAppsPage = false,
  isNavbarEnabled = false,
  setContactUsDialogOpen,
}) => {
  const history = useHistory()
  const [state, setState] = useState({
    isOffline: false,
    onDashboard: false,
    location: useLocation(),
    navigation: {
      pinnedSidebar: false,
    },
  })
  const [heading, setHeading] = useState("Dashobard")

  const { basic_info } = appState || {}

  const [parentState, dispatch] = useTracked()
  const { selectedTab } = parentState

  useEffect(() => {
    if (!notOnDashboard(state.location)) {
      setState({
        ...state,
        onDashboard: true,
      })
    }

    const onlineCallback = () => {
      setState({ ...state, isOffline: false })
    }

    const offlineCallback = () => {
      setState({ ...state, isOffline: true })
    }

    window.addEventListener("online", onlineCallback)
    window.addEventListener("offline", offlineCallback)
    selectProperTab(state.location)

    return () => {
      window.removeEventListener("online", onlineCallback)
      window.removeEventListener("offline", offlineCallback)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Will Recive props
  useEffect(() => {
    return history.listen((location) => {
      if (state.location.pathname !== location.pathname) {
        selectProperTab(location)
        setHeading(fetchHeadingFromLocation(location, appState?.app_id))
        if (notOnDashboard(location)) {
          setState({
            ...state,
            onDashboard: false,
            location: location,
          })
        } else {
          setState({
            ...state,
            onDashboard: true,
            location: location,
          })
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  /**
   * This will be helpful to apply `onDashboard` class based on the
   * pending App Data API call.
   *
   * If the current page is apps page, we don't touch the `onDashboard`
   * class at all.
   *
   * Also, Updating the tab based on the App data API response.
   */
  useEffect(() => {
    selectProperTab(state.location)

    if (!isAppsPage && isNavbarEnabled) {
      setState({
        ...state,
        onDashboard: !pendingAppAPI,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingAppAPI])

  const selectProperTab = (location) => {
    const heading = fetchHeadingFromLocation(location)
    setHeading(fetchHeadingFromLocation(state.location))
    let value
    if (NUDGES.includes(heading)) {
      value = 0
    } else if (INSIGHTS.includes(heading)) {
      value = 1
    } else {
      value = 0
    }
    dispatch({
      type: SET_TAB,
      value: value,
    })
  }

  return (
    <AppBar
      className={classNames(classes.appBar, {
        [classes.onDashboard]: state.onDashboard,
      })}
      elevation={0}
      style={{
        boxShadow: "0px 1px 5px 0px #00284529",
      }}
    >
      <Helmet
        title={heading && heading !== "" ? `${heading} | Apxor` : "Apxor"}
      />
      <Toolbar disableGutters={state.onDashboard}>
        {!state.onDashboard && <Logo />}

        {state.onDashboard && (
          <>
            <BootstrapTooltip
              placement="bottom"
              title={basic_info?.app_name}
            >
              <Avatar
                className={classes.logoAvatar}
                alt={basic_info?.app_name}
                src={basic_info?.icon_url}
                style={{
                  marginLeft: "30px",
                }}
                imgProps={{
                  onError: (e) => {
                    e.target.src = window.apxorAppInfo.favicon
                  },
                }}
              />
            </BootstrapTooltip>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={selectedTab}
              style={{ marginLeft: 16, marginTop: 16 }}
              onChange={(_, value) => {
                // We have to clear the query whenever the tab gets changed
                if (selectedTab !== value) {
                }
                dispatch({
                  type: SET_TAB,
                  value: value,
                })
                if (value === 0) {
                  history.push(`/apps/${appState.app_id}/dashboard`)
                } else if (value === 1) {
                  history.push(
                    `/apps/${appState.app_id}/${getDefaultPage(appState)}`,
                  )
                }
              }}
            >
              <Tab label={"Behavioural Cues"} />
              {isAccessable(appState, FEATURE_KEY_ENUM.ANALYTICS) && (
                <Tab label={"Product Intelligence"} />
              )}
            </Tabs>
          </>
        )}
        <div style={{ flex: 1 }} />
        <UserMenu setContactUsDialogOpen={setContactUsDialogOpen} />
      </Toolbar>
      <Dialog
        status={state.isOffline}
        noActions
      >
        <Typography variant="h6">{NO_CONNECTION_MESSAGE}</Typography>
      </Dialog>
    </AppBar>
  )
}

export const AppHeader = withStyles(styles, { withTheme: true })(
  AppHeaderWrapper,
)
