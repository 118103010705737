import { Grid, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useDeepCompareEffect } from "../../../utils/use-deep-compare"
import useOrganization from "../hooks/use-organization"
import { useTrackedState } from "../store"
import OrgCard, { OrgCardSkeleton } from "./OrgCard"
import { apxTheme } from "../../ApplicationFrame/styling/CustomCSS"

export function OrgsGridSkeleton() {
  return (
    <Grid
      container
      spacing={2}
    >
      {[...Array(9)].map((_, idx) => (
        <Grid
          item
          xs={4}
          key={idx}
        >
          <OrgCardSkeleton />
        </Grid>
      ))}
    </Grid>
  )
}

export default function OrgsGrid({ searchText, startTransition }) {
  const { getOrgs } = useOrganization()
  const { org_loading } = useTrackedState()

  const [orgs, setOrgs] = useState([])
  const [filteredOrgs, setFilteredOrgs] = useState(orgs)

  useEffect(() => {
    ;(async () => {
      const res = await getOrgs()
      setOrgs(res)
      setFilteredOrgs(res)
    })()
  }, [getOrgs])

  useDeepCompareEffect(() => {
    startTransition(() => {
      setFilteredOrgs(
        orgs?.filter((org) =>
          org?.name?.toLowerCase().includes(searchText?.toLowerCase()),
        ) ?? [],
      )
    })
  }, [orgs, searchText, startTransition])

  return org_loading ? (
    <OrgsGridSkeleton />
  ) : (
    <Grid
      container
      spacing={2}
    >
      {filteredOrgs?.length > 0 ? (
        filteredOrgs.map((org, idx) => (
          <Grid
            item
            xs={4}
            key={idx}
          >
            <OrgCard
              org={org}
              setOrgs={setOrgs}
            />
          </Grid>
        ))
      ) : (
        <Grid
          xs={12}
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Typography
            style={{
              color: apxTheme.palette.text.primary,
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            No Organisations Found!
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}
