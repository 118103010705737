import { Tooltip, makeStyles, withStyles } from "@material-ui/core"
import PropTypes from "prop-types"

const DarkTooltip = withStyles((theme) => ({
  popperArrow: {
    "& .MuiTooltip-arrow": {
      color: theme.palette.text.primary,
    },
  },
  tooltip: {
    fontSize: 12,
    fontWeight: 600,
  },
}))(Tooltip)

const LightTooltip = withStyles((theme) => ({
  popperArrow: {
    "& .MuiTooltip-arrow": {
      color: theme.palette.primary.main,
    },
  },
  tooltip: {
    fontSize: 12,
    fontWeight: 600,
  },
}))(Tooltip)

const useStyles = makeStyles((theme) => ({
  tooltip: (props) => ({
    color: !props?.dark ? theme.palette.text.primary : "#fff",
    border: !props?.dark ? `1.5px solid ${theme.palette.primary.main}` : `none`,
    backgroundColor: !props?.dark ? "#fff" : theme.palette.text.primary,
    marginBottom: props?.placement?.includes("top") ? "6px !important" : 0,
    marginLeft: props?.placement?.includes("right") ? "6px !important" : 0,
    borderRadius: ["top", "bottom"]?.includes(props?.placement)
      ? "12px"
      : props?.placement === "right"
      ? "7px 12px 12px 7px"
      : "12px 8px 8px 12px",
  }),
}))

/**
 * A tooltip with the theme's colors.
 * @param {import("@material-ui/core").TooltipProps}
 */
export default function ThemedTooltip({
  children,
  title,
  placement = "top",
  arrow = true,
  dark = false,
  style = {},
  ...props
}) {
  const classes = useStyles({ dark, placement })

  const TooltipComponent = dark ? DarkTooltip : LightTooltip

  return (
    <TooltipComponent
      title={title}
      placement={placement}
      arrow={arrow}
      style={{ ...style }}
      {...props}
      classes={classes}
    >
      {children}
    </TooltipComponent>
  )
}

const placementOptions = [
  "bottom-end",
  "bottom-start",
  "bottom",
  "left-end",
  "left-start",
  "left",
  "right-end",
  "right-start",
  "right",
  "top-end",
  "top-start",
  "top",
]

const titleOptions = [PropTypes.string, PropTypes.node]

ThemedTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOf(titleOptions).isRequired,
  placement: PropTypes.oneOf(placementOptions),
  arrow: PropTypes.bool,
  dark: PropTypes.bool,
}
