export const FEATURES_LIST_ENUM = Object.freeze({
  NUDGES: {
    id: "Nudges",
    children: {
      DASHBOARD: { label: "Dashboard", value: "APX_DASHBOARD" },
      AB: { label: "A/B Testing", value: "APX_AB" },
      CAMPAIGNS: { label: "Campaigns", value: "APX_WALKTHROUGHS" },
      SURVEYS: { label: "Surveys", value: "APX_SURVEYS" },
      PUSH: { label: "Push", value: "APX_PUSH" },
      NEW_SURVEYS: {
        label: "New Surveys",
        value: "APX_NEW_SURVEYS",
      },
      BADGES: {
        label: "Badges",
        value: "APX_BADGES",
      },
      COACHMARKS_V2: {
        label: "Coachmarks V2",
        value: "APX_COACHMARKS_V2",
      },
      NUDGE_VERSION_HISTORY: {
        label: "Nudge Version History",
        value: "APX_NUDGE_VERSION_HISTORY",
      },
      WEB_VIEW_TEMPLATES: {
        label: "Web View Templates",
        value: "APX_WEB_VIEW_TEMPLATES",
      },
      SCREEN_EXPLAINER: {
        label: "Screen Explainer",
        value: "APX_SCREEN_EXPLAINER",
      },
    },
  },
  ANALYTICS: {
    id: "Analytics",
    children: {
      TEMPLATES_RETENTION: { label: "Retention", value: "APX_RETENTION" },
      UNINSTALLS: { label: "Uninstalls", value: "APX_UNINSTALLS" },
      FUNNELS: { label: "Funnels", value: "APX_FUNNELS" },
      PATHS: { label: "Paths", value: "APX_PATH" },
    },
  },
  REPORTING: {
    id: "Reporting",
    children: {
      CUSTOM_REPORTS: { label: "Custom Reports", value: "APX_CUSTOM_REPORTS" },
    },
  },
  EXPLORER: {
    id: "Explorer",
    children: {
      EVENT_EXPLORER: { label: "Event Explorer", value: "APX_EVENT_ANALYSIS" },
      USER_EXPLORER: { label: "User Explorer", value: "APX_USER_EXPLORER" },
    },
  },
  TARGET_AUDIENCE: {
    id: "Target Audience",
    children: {
      COHORTS: { label: "Cohorts", value: "APX_COHORTS" },
      SEGMENTS: { label: "Segments", value: "APX_SEGMENTS" },
      DYNAMIC_COHORTS: {
        label: "Dynamic Cohorts",
        value: "APX_DYNAMIC_COHORTS",
      },
    },
  },
  SETTINGS: {
    id: "Settings",
    children: {
      MANAGEPLAN: { label: "Manage Plan", value: "APX_SETTINGS_MANAGE_PLAN" },
      IAM: { label: "IAM", value: "APX_SETTINGS_IAM" },
      SDK: { label: "SDK", value: "APX_SETTINGS_SDK" },
      TEST_DEVICES: {
        label: "Test Devices",
        value: "APX_SETTINGS_TEST_DEVICES",
      },
      DOWNLOADS: { label: "Downloads", value: "APX_SETTINGS_DATA" },
      EVENTS_MANAGEMENT: {
        label: "Events Management",
        value: "APX_SETTINGS_EVENTS_MANAGEMENT",
      },
    },
  },
  THEMES: {
    id: "Themes",
    children: {
      GLOBAL_THEMES: { label: "Global Themes", value: "APX_GLOBAL_THEMES" },
      CUSTOM_FONTS: { label: "Custom Fonts", value: "APX_CUSTOM_FONTS" },
    },
  },
})

export const FEATURES_LIST = Object.freeze({
  Nudges: [
    "APX_DASHBOARD",
    "APX_WALKTHROUGHS",
    "APX_AB",
    "APX_SURVEYS",
    "APX_PUSH",
    "APX_NEW_SURVEYS",
    "APX_BADGES",
    "APX_COACHMARKS_V2",
    "APX_NUDGE_VERSION_HISTORY",
    "APX_WEB_VIEW_TEMPLATES",
    "APX_SCREEN_EXPLAINER",
  ],
  Analytics: ["APX_RETENTION", "APX_UNINSTALLS", "APX_FUNNELS", "APX_PATH"],
  Reporting: ["APX_CUSTOM_REPORTS"],
  Explorer: ["APX_EVENT_ANALYSIS", "APX_USER_EXPLORER"],
  "Target Audience": ["APX_COHORTS", "APX_SEGMENTS", "APX_DYNAMIC_COHORTS"],
  Settings: [
    "APX_SETTINGS_MANAGE_PLAN",
    "APX_SETTINGS_IAM",
    "APX_SETTINGS_SDK",
    "APX_SETTINGS_TEST_DEVICES",
    "APX_SETTINGS_DATA",
    "APX_SETTINGS_EVENTS_MANAGEMENT",
  ],
  Themes: ["APX_GLOBAL_THEMES", "APX_CUSTOM_FONTS"],
})
