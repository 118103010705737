import { Grid, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { isSuper } from "../../../config"
import { useAuth } from "../../../contexts/AuthContext"
import { useDeepCompareEffect } from "../../../utils/use-deep-compare"
import { apxTheme } from "../../ApplicationFrame/styling/CustomCSS"
import useOrganization from "../../Organizations/hooks/use-organization"
import { useTrackedState } from "../../Organizations/store"
import AppCard, { AppCardSkeleton } from "./AppCard"

export function AppsGridSkeleton() {
  return (
    <Grid
      container
      spacing={2}
    >
      {[...Array(9)].map((_, idx) => (
        <Grid
          item
          xs={4}
          key={idx}
        >
          <AppCardSkeleton />
        </Grid>
      ))}
    </Grid>
  )
}

export default function AppsGrid({ searchText, startTransition }) {
  const {
    user: { email },
  } = useAuth()
  const { orgId } = useParams()
  const { getAppsOfOrg } = useOrganization()
  const {
    org_data: { owner },
    org_loading,
  } = useTrackedState()

  const [apps, setApps] = useState([])
  const [filteredApps, setFilteredApps] = useState(
    apps?.filter(
      (app) =>
        email === owner ||
        isSuper(email) ||
        app?.basic_info?.customers?.some(
          ({ customer_id }) => customer_id === email,
        ),
    ),
  )

  useEffect(() => {
    ;(async () => {
      const res = await getAppsOfOrg(orgId)
      setApps(res)
      setFilteredApps(
        res?.filter(
          (app) =>
            email === owner ||
            isSuper(email) ||
            app?.basic_info?.customers?.some(
              ({ customer_id }) => customer_id === email,
            ),
        ),
      )
    })()
  }, [email, getAppsOfOrg, orgId, owner])

  useDeepCompareEffect(() => {
    startTransition(() => {
      setFilteredApps(
        apps
          ?.filter(
            (app) =>
              email === owner ||
              isSuper(email) ||
              app?.basic_info?.customers?.some(
                ({ customer_id }) => customer_id === email,
              ),
          )
          ?.filter((app) =>
            app?.basic_info?.app_name
              ?.toLowerCase()
              ?.includes(searchText.toLowerCase()),
          ),
      )
    })
  }, [email, apps, owner, searchText, startTransition])
  return org_loading ? (
    <AppsGridSkeleton />
  ) : (
    <Grid
      container
      spacing={2}
    >
      {filteredApps?.length > 0 ? (
        filteredApps.map((app, idx) => (
          <Grid
            item
            xs={4}
            key={idx}
          >
            <AppCard
              app={app}
              setApps={setApps}
            />
          </Grid>
        ))
      ) : (
        <Grid
          xs={12}
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Typography
            style={{
              color: apxTheme.palette.text.primary,
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            No Apps Found!
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}
