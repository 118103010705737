import { Button, IconButton, Typography } from "@material-ui/core"
import PlusIcon from "@material-ui/icons/Add"
import EditIcon from "@material-ui/icons/CreateOutlined"
import { Suspense, lazy, useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { isSuper } from "../../../config"
import { useAuth } from "../../../contexts/AuthContext"
import { capitalizeEachWord } from "../../../utils"
import { apxTheme } from "../../ApplicationFrame/styling/CustomCSS"
import { useTrackedState } from "../../Organizations/store"
import HStack from "../../ReusableComponents/HStack"
import Loading from "../../ReusableComponents/Loading"
import SearchBar from "../../ReusableComponents/SearchBar"
import Snackbar from "../../ReusableComponents/Snackbar"
import ThemedTooltip from "../../ReusableComponents/ThemedTooltip"
import DialogLoader from "../../ReusableComponents/skeletons/DialogLoader"

const NewOrgDialog = lazy(() =>
  import("../../Organizations/components/NewOrgDialog"),
)
const EditOrgDetailsDialog = lazy(() =>
  import("../../Organizations/components/EditOrgDetailsDialog"),
)

export default function AppsListingHeader({
  searchText,
  setSearchText,
  isPending,
}) {
  const {
    user: { email },
  } = useAuth()
  const { orgId } = useParams()
  const {
    org_loading,
    org_data: { owner, name, icon_url },
  } = useTrackedState()

  const [openNewOrgDialog, setOpenNewOrgDialog] = useState(false)
  const [openEditOrgDetailsDialog, setOpenEditOrgDetailsDialog] =
    useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState("")

  const isCurrentUserAdminOrOwner = useMemo(
    () => email === owner || isSuper(email),
    [email, owner],
  )

  return (
    <>
      {!org_loading && snackBarMessage !== "" && (
        <Snackbar
          onClose={() => {
            setSnackBarMessage("")
          }}
        >
          <strong>{snackBarMessage}</strong>
        </Snackbar>
      )}
      <HStack justify="space-between">
        <HStack spacing="4px">
          {name ? (
            <>
              <Typography
                style={{
                  color: "#002845",
                  fontSize: "24px",
                  fontWeight: "700",
                }}
              >
                {capitalizeEachWord(name)}&apos;s Apps
              </Typography>
              {isCurrentUserAdminOrOwner && (
                <ThemedTooltip
                  title="Edit Org Details"
                  placement="right"
                >
                  <IconButton onClick={() => setOpenEditOrgDetailsDialog(true)}>
                    <EditIcon
                      fontSize="small"
                      style={{
                        color: "#002845",
                      }}
                    />
                  </IconButton>
                </ThemedTooltip>
              )}
            </>
          ) : (
            <HStack
              style={{
                width: "80px",
                height: "30px",
              }}
            ></HStack>
          )}
        </HStack>
        <HStack spacing="8px">
          {isPending && (
            <Loading
              color={apxTheme.palette.primary.main}
              size={20}
            />
          )}
          <SearchBar
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            onClear={() => {
              setSearchText("")
            }}
            placeholder="Search by App Name"
            style={{
              minWidth: "360px",
            }}
          />
          {(owner === email || isSuper(email)) && (
            <Link
              to={`/orgs/${orgId}/apps/create`}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<PlusIcon />}
                style={{
                  minHeight: "44px",
                }}
              >
                Add Application
              </Button>
            </Link>
          )}
        </HStack>
      </HStack>
      {openNewOrgDialog && (
        <Suspense fallback={<DialogLoader />}>
          <NewOrgDialog
            open={openNewOrgDialog}
            onClose={() => setOpenNewOrgDialog(false)}
            setSnackBarMessage={setSnackBarMessage}
          />
        </Suspense>
      )}
      {openEditOrgDetailsDialog && (
        <Suspense fallback={<DialogLoader />}>
          <EditOrgDetailsDialog
            open={openEditOrgDetailsDialog}
            onClose={() => setOpenEditOrgDetailsDialog(false)}
            org={{ name, icon_url }}
            setSnackBarMessage={setSnackBarMessage}
          />
        </Suspense>
      )}
    </>
  )
}
