import { Button, Typography } from "@material-ui/core"
import PlusIcon from "@material-ui/icons/Add"
import { Suspense, lazy, useState, useTransition } from "react"
import { isSuper } from "../../../config"
import { useAuth } from "../../../contexts/AuthContext"
import { apxTheme } from "../../ApplicationFrame/styling/CustomCSS"
import HStack from "../../ReusableComponents/HStack"
import Loading from "../../ReusableComponents/Loading"
import SearchBar from "../../ReusableComponents/SearchBar"
import VStack from "../../ReusableComponents/VStack"
import DialogLoader from "../../ReusableComponents/skeletons/DialogLoader"
import OrgsGrid from "../components/OrgsGrid"
import OrgLayout from "../layouts/OrgLayout"

const NewOrgDialog = lazy(() => import("../components/NewOrgDialog"))

export default function OrganisationsListPage() {
  const {
    user: { email },
  } = useAuth()

  const [openNewOrgDialog, setOpenNewOrgDialog] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [isPending, startTransition] = useTransition()

  return (
    <OrgLayout>
      <VStack
        spacing="50px"
        style={{
          padding: "40px",
          backgroundColor: "#F9F9F9",
          minHeight: "100vh",
        }}
      >
        <HStack justify="space-between">
          <Typography
            style={{
              color: "#002845",
              fontSize: "24px",
              fontWeight: "700",
            }}
          >
            Your Organisations
          </Typography>
          <HStack spacing="8px">
            {isPending && (
              <Loading
                color={apxTheme.palette.primary.main}
                size={20}
              />
            )}
            <SearchBar
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
              onClear={() => {
                setSearchText("")
              }}
              placeholder="Search by Organisation Name"
              style={{
                minWidth: "300px",
              }}
            />
            {isSuper(email) && (
              <Button
                startIcon={<PlusIcon />}
                size="large"
                color="primary"
                variant="contained"
                onClick={() => setOpenNewOrgDialog(true)}
              >
                Add Organisation
              </Button>
            )}
          </HStack>
        </HStack>
        <OrgsGrid
          searchText={searchText}
          startTransition={startTransition}
        />
      </VStack>
      {isSuper(email) && openNewOrgDialog && (
        <Suspense fallback={<DialogLoader />}>
          <NewOrgDialog
            open={openNewOrgDialog}
            onClose={() => setOpenNewOrgDialog(false)}
          />
        </Suspense>
      )}
    </OrgLayout>
  )
}
