import { useEffect, useState, useTransition } from "react"
import useApxorClient from "../../../utils/use-apxor-client"
import VStack from "../../ReusableComponents/VStack"
import AppsGrid from "../components/AppsGrid"
import AppsListingHeader from "../components/AppsListingHeader"
import AppsLayout from "../layouts/AppsLayout"

export default function AppsListingPage() {
  const [searchText, setSearchText] = useState("")
  const [isPending, startTransition] = useTransition()
  const { logEvent } = useApxorClient()
  const [eventCount, setEventCount] = useState(0)

  useEffect(() => {
    if (eventCount === 0) {
      logEvent("Apps_PageLaunched", { source: "Orgs-page" })
      setEventCount((eventCount) => eventCount + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppsLayout>
      <VStack
        spacing="50px"
        style={{
          padding: "40px",
          backgroundColor: "#F9F9F9",
          minHeight: "100vh",
        }}
      >
        <AppsListingHeader
          searchText={searchText}
          setSearchText={setSearchText}
          isPending={isPending}
        />
        <AppsGrid
          searchText={searchText}
          startTransition={startTransition}
        />
      </VStack>
    </AppsLayout>
  )
}
