import { useCallback } from "react"
import { useParams } from "react-router-dom"
import { useTrackedState as useOrgState } from "../../../Application/Organizations/store"
import { isSuper } from "../../../config"
import { useAuth } from "../../../contexts/AuthContext"
import { capitalizeEachWord } from "../../../utils"
import {
  useDeepCompareCallback,
  useDeepCompareCallbackNoCheck,
  useDeepCompareMemoNoCheck,
} from "../../../utils/use-deep-compare"
import { SET_AUTH_ERROR, SET_AUTH_LOADING } from "../../Auth/constants"
import { useDispatch } from "../../Auth/store"
import {
  ADDITIONAL_ROLES_ENUM,
  ALL_FLAGS,
  ALL_PERMISSIONS,
  FURNISHED_FLAGS,
  ROLES_ENUM,
  isCSM,
} from "../../Dashboard/components/settings/constants"
import {
  FEATURES_LIST,
  FEATURES_LIST_ENUM,
} from "../../Dashboard/components/settings/general/constants"
import { useTrackedState } from "../../IntegrationExperience/store"
import { updatePasswordAPI, updateProfileDetailsAPI } from "../actions"

export default function useUser() {
  const auth = useAuth()
  const { orgId } = useParams()
  const dispatch = useDispatch()
  const { org_data: { owner = "" } = {} } = useOrgState()
  const {
    app_data: { basic_info: { customers } = {}, features },
  } = useTrackedState()

  /**
   * @typedef {Object} IntermediateAuthState
   * @property {boolean} loading Whether the auth state is loading.
   * @property {string} error The error message.
   */

  /**
   * This function resets `auth_loading` and `auth_error` states.
   * @private @memberof {@link useUser}
   * @param {IntermediateAuthState} payload
   */
  const resetIntermediateUserStates = useCallback(
    ({ loading = false, error = "" }) => {
      dispatch({
        type: SET_AUTH_LOADING,
        payload: loading,
      })
      dispatch({
        type: SET_AUTH_ERROR,
        payload: error,
      })
    },
    [dispatch],
  )

  /**
   * This function updates the user's password.
   * @public @memberof {@link useUser}
   * @param {string} oldPassword The current password.
   * @param {string} newPassword The new password.
   * @returns {Promise<boolean>} A promise that resolves to `true` if the password was updated successfully, `false` otherwise.
   */
  const updatePassword = useDeepCompareCallback(
    async (oldPassword, newPassword) => {
      try {
        resetIntermediateUserStates({ loading: true, error: "" })
        await updatePasswordAPI(auth, {
          userId: auth?.user?.email,
          oldPassword: oldPassword,
          newPassword: newPassword,
        })
        resetIntermediateUserStates({ loading: false, error: "" })
        return true
      } catch (error) {
        resetIntermediateUserStates({ loading: false, error: error.message })
        return false
      }
    },
    [auth, resetIntermediateUserStates],
  )

  /**
   * This function updates the user's profile details.
   * @public @memberof {@link useUser}
   * @param {import("../actions").ProfileDetailsDTO} details The profile details to be updated.
   * @returns {Promise<import("../actions").UpdateProfileReturnDTO>} A promise that resolves to the updated profile details.
   */
  const updateUserDetails = useDeepCompareCallback(
    async (details) => {
      try {
        resetIntermediateUserStates({ loading: true, error: "" })
        const { ok, name } = await updateProfileDetailsAPI(auth, details)
        if (ok) {
          resetIntermediateUserStates({ loading: false, error: "" })
          return { ok, name }
        }
      } catch (error) {
        resetIntermediateUserStates({ loading: false, error: error.message })
        return { ok: false }
      }
    },
    [auth, resetIntermediateUserStates],
  )

  const getFeaturesListOfFlag = (flagValue) => {
    return FEATURES_LIST[capitalizeEachWord(flagValue.replace("_", " "))]
  }

  const getFlagsAndFeatures = (flags = []) => {
    let flagsAndFeatures = [...flags]
    if (flags.length > 0) {
      flags?.forEach((flag) => {
        if (!flag.includes("APX_")) {
          flagsAndFeatures = [
            ...flagsAndFeatures,
            ...getFeaturesListOfFlag(flag),
          ]
        }
      })
    }
    return flagsAndFeatures
  }

  const flagsArray = (featuresArr) => {
    let arr = []
    featuresArr?.map((feature) => {
      return Object.entries(FEATURES_LIST).map((flagsArr) =>
        flagsArr[1].map(
          (flags) =>
            flags.includes(feature) &&
            !arr.includes(flagsArr[0].toLowerCase()) &&
            arr.push(
              flagsArr[0].toLowerCase() === "target audience"
                ? flagsArr[0].toLowerCase().split(" ").join("_")
                : flagsArr[0].toLowerCase(),
            ),
        ),
      )
    })
    return arr
  }

  const appFlagsArr = flagsArray(features)
  const appFeaturesArr = features

  const getRefinedUser = useDeepCompareCallbackNoCheck(
    (user) => {
      if (isSuper(user?.customer_id)) {
        return {
          ...user,
          role: ADDITIONAL_ROLES_ENUM.APXOR_OWNER,
          flags: getFlagsAndFeatures(ALL_FLAGS),
          permissions: ALL_PERMISSIONS,
          isAdmin: true,
          isOrgOwner: owner === user?.email,
        }
      } else if (user?.customer_id === owner) {
        return {
          ...user,
          role: ROLES_ENUM.ORG_OWNER,
          flags: getFlagsAndFeatures(appFlagsArr),
          permissions: ALL_PERMISSIONS,
          isAdmin: false,
          isOrgOwner: true,
        }
      } else {
        return isCSM(user?.role, user?.customer_id, orgId)
          ? {
              ...user,
              flags: getFlagsAndFeatures(
                user?.flags.length > 0 ? user?.flags : FURNISHED_FLAGS,
              ),
              role: ADDITIONAL_ROLES_ENUM.CSM,
              permissions: user?.permissions,
              isAdmin: false,
              isOrgOwner: false,
            }
          : {
              ...user,
              role: Object.values(ROLES_ENUM).find(
                ({ value }) => value === user?.role,
              ),
              flags: getFlagsAndFeatures(
                user?.flags.length > 0 ? user?.flags : FURNISHED_FLAGS,
              ),
              permissions: user?.permissions,
              isAdmin: false,
              isOrgOwner: false,
            }
      }
    },
    [appFlagsArr, getFlagsAndFeatures, orgId, owner],
  )

  const currentRefinedUser = useDeepCompareMemoNoCheck(() => {
    const fullUser =
      isSuper(auth?.user?.email) || auth?.user?.email === owner
        ? { customer_id: auth?.user?.email }
        : customers?.find(
            (customer) => customer?.customer_id === auth?.user?.email,
          )
    return getRefinedUser(fullUser)
  }, [auth?.user?.email, customers, getRefinedUser, owner])

  const featuresList = (flagValue) =>
    Object.values(
      Object.values(FEATURES_LIST_ENUM).find(
        ({ id }) => id.toLowerCase().split(" ").join("_") === flagValue,
      )?.children,
    ).map(({ value }) => value)

  const currentRefinedUserFlagsArr = flagsArray(currentRefinedUser.flags)

  const getFeatureLabel = (flagValue, feature) => {
    return Object.values(
      Object.values(FEATURES_LIST_ENUM).find(
        ({ id }) =>
          id ===
          (() => {
            if (flagValue === "target_audience") {
              return "Target Audience"
            } else {
              return (
                flagValue[0].toUpperCase() +
                flagValue.slice(1, flagValue.length)
              )
            }
          })(),
      )?.children,
    )
      ?.filter(({ value }) => value === feature)
      ?.map(({ label }) => label)[0]
  }

  return {
    updatePassword,
    updateUserDetails,
    getRefinedUser,
    currentRefinedUser,
    appFlagsArr,
    appFeaturesArr,
    featuresList,
    currentRefinedUserFlagsArr,
    flagsArray,
    getFeatureLabel,
    getFeaturesListOfFlag,
    getFlagsAndFeatures,
  }
}
