import produce from "immer"
import get from "lodash/get"
import set from "lodash/set"
import moment from "moment"
import { isExperienceDashboard } from "../../../../../config"
import {
  SET_ENABLE_TIME_BASED_TYPE,
  SET_ENABLE_TOUCH,
  SET_TERMINATE_INFO_ATTRIBUTES_USER,
  SET_TERMINATE_INFO_ENABLE_ATTRIBUTES,
  SET_TERMINATE_INFO_ENABLE_GOAL_EVENTS,
  SET_TERMINATE_INFO_GOAL_EVENTS_EVENTS,
  SET_TIME_BASED_DAYS,
} from "../../../components/walkthroughs/components/design/constants"
import {
  ADD_NEW_PROPERTY_IN_AUDIENCE,
  ADD_STEP_TO_VARIANT,
  ADD_VARIANT,
  APP_PLATFORMS,
  DELETE_STEP_FROM_VARIANT,
  DELETE_VARIANT,
  ENABLE_CONDITIONS,
  ON_COPY_STEP_VARIANT,
  REMOVE_AUDIENCE_ATTRIBUTE,
  REMOVE_TEST_DEVICE,
  SCHEDULING_ENUM,
  SET_ADD_CONDITION,
  SET_APP_SCREENS,
  SET_APP_SCREEN_NAMES,
  SET_AUDIENCE_ATTRIBUTE,
  SET_AUDIENCE_TYPE,
  SET_AUDIENCE_USER_ATTR,
  SET_AUTO_DISMISS_DURATION,
  SET_AUTO_DISMISS_ENABLED,
  SET_AUTO_PUBLISH_WINNER,
  SET_CAMPAIGN_PUBLISH_CG,
  SET_CLIENT_EVENTS,
  SET_COHORTS,
  SET_COHORT_ID,
  SET_CONFIG,
  SET_CONTEXT_TYPE,
  SET_CURRENT_VARIANT_STEP,
  SET_DEFAULT_TEST_DEVICES,
  SET_DEMO_UPSERT_CONDITION,
  SET_DIDNOT_DO_EVENTS,
  SET_DYNAMIC_COHORTS,
  SET_DYNAMIC_COHORT_ID,
  SET_ENABLE_TESTING,
  SET_EVENTS,
  SET_EXCLUDE_ANONYMOUS_USERS,
  SET_EXPERIMENT,
  SET_FREQ_COUNT,
  SET_FREQ_DAY_LIMIT,
  SET_FREQ_LIMITS_ENABLED,
  SET_FREQ_SESSION_LIMIT,
  SET_FREQ_VALIDITY,
  SET_GOAL_EVENT,
  SET_GOAL_MET_CHECK,
  SET_META_CONFIG_TYPE,
  SET_META_DESC,
  SET_META_IS_MWEB,
  SET_META_NAME,
  SET_META_PLATFORM,
  SET_META_TAGS,
  SET_META_TYPE,
  SET_NTH_EVENT,
  SET_OPERATOR_AND_SEQUENCE_ENABLED,
  SET_PRIORITY,
  SET_PUBLISH_END_DATE,
  SET_PUBLISH_SHOW_AT_SPECIFIC_TIME,
  SET_PUBLISH_START_DATE,
  SET_PUBLISH_TIME_LIMITS_END,
  SET_PUBLISH_TIME_LIMITS_START,
  SET_REMOVE_CONDITION,
  SET_RETAINED_DAY_FROM,
  SET_RETAINED_DAY_TO,
  SET_ROLL_INTERVAL,
  SET_ROLL_RECURR,
  SET_ROLL_STEP,
  SET_SCHEDULING_INSTANCE,
  SET_SCHEDULING_TIME,
  SET_SEGMENT,
  SET_SEGMENTS,
  SET_SELECTED_TEST_DEVICE,
  SET_SELECTED_TEST_VARIANT,
  SET_SESSION_LIMIT_FROM,
  SET_SESSION_LIMIT_TO,
  SET_SNACKBAR_ERROR_MESSAGE,
  SET_SURVEY_DELAY,
  SET_SURVEY_TYPE,
  SET_TARGET_ANONYMOUS_USERS_ONLY,
  SET_TARGET_PERCENTAGE,
  SET_TEST_DEVICES,
  SET_TEST_END_DATE,
  SET_TEST_SHOW_AT_SPECIFIC_TIME,
  SET_TEST_START_DATE,
  SET_TEST_TIME_LIMITS_END,
  SET_TEST_TIME_LIMITS_START,
  SET_TIME_LIMITS_END,
  SET_TIME_LIMITS_START,
  SET_TIME_SPENT,
  SET_TOGGLE_APP_VERSION_FILTER,
  SET_TOGGLE_RECURR,
  SET_TOGGLE_RETAINED_DAY,
  SET_TOGGLE_ROLL,
  SET_TOGGLE_SESSION_ATTRIBUTES,
  SET_TOGGLE_SESSION_LIMITS,
  SET_TOGGLE_TIME_LIMITS_ENABLED,
  SET_TOGGLE_USER_ATTRIBUTES,
  SET_UI,
  SET_UIS,
  SET_UPDATE_CONDITION,
  SET_VALIDITY_END,
  SET_VALIDITY_START,
  SET_VARIANT_PERCENTAGE,
  SET_VERSION_OPERATOR,
  SET_VERSION_VALUE,
  TARGET_AUDIENCE_ENUM,
  UPDATE_TEST_DEVICES,
} from "./constants"

export const getTimeLimitsEnabledKey = (platform) => {
  if (platform === APP_PLATFORMS.ios) {
    return "time_limits_in_day"
  } else {
    return "at_specific_time"
  }
}

const DEFAULT_SCHEDULING_STATE = {
  schedule_date: moment(moment().toISOString())
    .utcOffset("+05:30")
    .format("YYYY-MM-DD"),
  schedule_time: moment(moment().toISOString())
    .utcOffset("+05:30")
    .format("HH:mm"),
  schedule: SCHEDULING_ENUM.NOW,
  isRecurEnabled: false,
  rollSegment: false,
  rollInterval: 1,
  recur: "DAY",
  step: 1,
}

export const initial_state = {
  config: {
    surveyType: "legacy",
    audience: {
      audience_type: "ALL",
      only_anonymous: false,
      exclude_anonymous: false,
      attributes: { user: [], session: [] },
      limited: {},
      cohort_id: "",
      segment_id: "",
      dynamic_cohort_id: "",
      end_time: moment().utc().endOf("day").toISOString().slice(0, 23) + "Z",
      start_time:
        moment()
          .subtract("days", 15)
          .utc()
          .startOf("day")
          .toISOString()
          .slice(0, 23) + "Z",
    },
    c_type: 0,
    conditions: [],
    sequence_enabled: false,
    pre_cs: [],
    pre_cs_seq_enabled: false,
    terminate_info: {
      auto_dismiss: false,
      duration: 5000,
      conditions: [],
      enable_time_based: false,
      time_based: {
        type: "relative",
        duration_seconds: 10,
        days: 1,
      },
      enable_goal_events: false,
      goal_events: {
        sequence_enabled: false,
        events: [],
      },
      enable_touch: false,
      touch: {
        target_view: 1,
      },
      enable_attributes: false,
      attributes: {
        session: [],
        user: [],
      },
    },
    enabled: true,
    cg_percentage: 3,
    experiment: {
      publishSettings: {
        CG: 3,
        time_settings: { time_limits: {} },
      },
    },
    frequency: {
      count: isExperienceDashboard() ? 10 : 1,
      time_interval: 1000,
      validity: "OVERALL",
      DSLimits: false,
    },
    meta: {
      name: "",
      description: "",
      config_type: "walkthrough",
      type: "WALKTHROUGH",
      tags: [],
      platform: "android",
      v2: true,
      isExperiment: false,
      version: 3,
      is_mweb: false,
    },
    mutation_id: null,
    overall_cfg: {
      ret_day: {},
      events: [],
      session: {},
      toggleRetDay: false,
      toggleSession: false,
    },
    published: false,
    scheduling: DEFAULT_SCHEDULING_STATE,
    goal_met_check: false,
    testing: { enabled: false, devices: [] },
    time_limits: { start_time: "10:00", end_time: "20:30" },
    time_limits_in_day: false,
    at_specific_time: false,
    validity: {
      start_date: moment().toISOString(),
      end_date: moment().add("days", 7).toISOString(),
    },
    theme: {
      custom_fonts: [],
    },
    ui: {},
    uis: [],
  },
  uisToDelete: [],
  app_events: [],
  app_screens: [],
  app_screen_names: [],
  cohorts: [],
  dynamic_cohorts: [],
  segments: [],
  client_events: [],
  test_devices: [],
  snackbarMessage: null,
}

export const reducer = produce((draft, action) => {
  let attrs

  if (draft.config.experiment) {
    if (!draft.config.experiment.publishSettings) {
      draft.config.experiment.publishSettings = {
        CG: 3,
        time_settings: {
          time_limits: {},
        },
      }
    }
  }

  switch (action.type) {
    case SET_CONFIG:
      draft.config = action.config
      break
    case SET_SURVEY_TYPE:
      draft.config.surveyType = action.value
      break
    case SET_EVENTS:
      draft.app_events = action.events
      break
    case SET_APP_SCREEN_NAMES:
      draft.app_screen_names = action.screens
      break
    case SET_APP_SCREENS:
      draft.app_screens = action.screens
      break
    case SET_META_TYPE:
      draft.config.meta.type = action.value
      break
    case SET_META_CONFIG_TYPE:
      let config_type = "walkthrough"
      let type = "walkthrough"
      switch (action.config_type) {
        case "surveys":
        case "survey":
          config_type = "survey"
          type = "SURVEY"
          break
        case "onboarding":
          config_type = "onboarding"
          type = "ONBOARDING"
          break
        default:
          break
      }

      if (draft.config.meta.config_type !== config_type) {
        draft.config.ui = {}
        draft.config.uis = []
      }

      draft.config.meta.config_type = config_type
      draft.config.meta.type = type
      break
    case SET_META_NAME:
      draft.config.meta.name = action.name
      break
    case SET_META_TAGS:
      draft.config.meta.tags = action.tags
      break
    case SET_META_DESC:
      draft.config.meta.description = action.desc
      break
    case SET_META_PLATFORM:
      draft.config.meta.platform = action.platform
      break
    case SET_META_IS_MWEB:
      draft.config.meta.is_mweb = action.is_mweb
      break
    case SET_AUDIENCE_TYPE:
      draft.config.audience.audience_type = action.audience_type
      draft.config.scheduling.isRecurEnabled = false
      switch (action.audience_type) {
        case TARGET_AUDIENCE_ENUM.ALL.key:
          draft.config.audience.cohort_id = ""
          draft.config.audience.segment_id = ""
          draft.config.audience.dynamic_cohort_id = ""
          if (!draft.config.scheduling) {
            draft.config.scheduling = DEFAULT_SCHEDULING_STATE
          }
          draft.config.scheduling.step = DEFAULT_SCHEDULING_STATE.step
          draft.config.scheduling.rollSegment = false
          break
        case TARGET_AUDIENCE_ENUM.SEGMENT.key:
          draft.config.audience.cohort_id = ""
          draft.config.audience.segment_id = ""
          draft.config.audience.dynamic_cohort_id = ""
          break
        case TARGET_AUDIENCE_ENUM.COHORT.key:
          draft.config.audience.cohort_id = ""
          draft.config.audience.segment_id = ""
          draft.config.audience.dynamic_cohort_id = ""
          if (!draft.config.scheduling) {
            draft.config.scheduling = DEFAULT_SCHEDULING_STATE
          }
          draft.config.scheduling.step = DEFAULT_SCHEDULING_STATE.step
          draft.config.scheduling.isRecurEnabled = false
          draft.config.scheduling.rollSegment = false
          break
        case TARGET_AUDIENCE_ENUM.NEW_USERS.key:
          draft.config.overall_cfg.ret_day.from = 0
          draft.config.overall_cfg.ret_day.to = 0
          break
        case TARGET_AUDIENCE_ENUM.DYANMIC_COHORT.key:
          draft.config.audience.dynamic_cohort_id = ""
          draft.config.audience.segment_id = ""
          draft.config.audience.cohort_id = ""
          if (!draft.config.scheduling) {
            draft.config.scheduling = DEFAULT_SCHEDULING_STATE
          }
          draft.config.scheduling.step = DEFAULT_SCHEDULING_STATE.step
          draft.config.scheduling.isRecurEnabled = false
          draft.config.scheduling.rollSegment = false
          break
        default:
          break
      }
      break
    case SET_AUDIENCE_USER_ATTR:
      draft.config.audience.attributes.user = action.user
      break
    case SET_SEGMENT:
      // Clear the Cohort ID
      draft.config.audience.cohort_id = ""
      draft.config.audience.dynamic_cohort_id = ""
      if (action.segment_id) {
        draft.config.audience.segment_id = action.segment_id
        draft.config.scheduling = DEFAULT_SCHEDULING_STATE
      }
      if (action.start_time) {
        draft.config.audience.start_time = action.start_time
      }
      if (action.end_time) {
        draft.config.audience.end_time = action.end_time
      }
      break
    case SET_COHORT_ID:
      draft.config.audience.segment_id = ""
      draft.config.audience.dynamic_cohort_id = ""
      draft.config.audience.cohort_id = action.cohort_id
      break
    case SET_DYNAMIC_COHORT_ID:
      draft.config.audience.segment_id = ""
      draft.config.audience.cohort_id = ""
      draft.config.audience.dynamic_cohort_id = action.dynamic_cohort_id
      break
    case SET_FREQ_COUNT:
      draft.config.frequency.count = action.count
      break
    case SET_FREQ_DAY_LIMIT:
      draft.config.frequency.day_lmt = action.value
      break
    case SET_FREQ_LIMITS_ENABLED:
      if (!action.enabled) {
        const { day_lmt, ses_lmt, ...freq } = draft.config.frequency
        draft.config.frequency = { ...freq }
      }
      draft.config.frequency.DSLimits = action.enabled
      break
    case SET_FREQ_SESSION_LIMIT:
      draft.config.frequency.ses_lmt = action.value
      break
    case SET_FREQ_VALIDITY:
      draft.config.frequency.validity = action.validity
      if (action.validity !== "OVERALL") {
        draft.config.frequency.DSLimits = false
        delete draft.config.frequency.day_lmt
        delete draft.config.frequency.ses_lmt
      }
      break
    case SET_RETAINED_DAY_TO:
      draft.config.overall_cfg.ret_day.to = action.value
      break
    case SET_RETAINED_DAY_FROM:
      draft.config.overall_cfg.ret_day.from = action.value
      break
    case SET_DIDNOT_DO_EVENTS:
      draft.config.overall_cfg.events = action.events
      break
    case SET_SESSION_LIMIT_FROM:
      draft.config.overall_cfg.session.from = action.value
      break
    case SET_SESSION_LIMIT_TO:
      draft.config.overall_cfg.session.to = action.value
      break
    case SET_TOGGLE_RETAINED_DAY:
      draft.config.overall_cfg.toggleRetDay = action.enabled
      if (action.enabled === false) {
        draft.config.overall_cfg.ret_day = {}
      } else {
        if (!draft.config.overall_cfg.ret_day)
          draft.config.overall_cfg.ret_day = {}
        draft.config.overall_cfg.ret_day.from = action?.from
        draft.config.overall_cfg.ret_day.to = action?.to
      }
      break
    case SET_TOGGLE_SESSION_LIMITS:
      draft.config.overall_cfg.toggleSession = action.enabled
      if (action.enabled === false) {
        draft.config.overall_cfg.session = {}
      } else {
        if (!draft.config.overall_cfg.session)
          draft.config.overall_cfg.session = {}
        draft.config.overall_cfg.session.from = action?.from
        draft.config.overall_cfg.session.to = action?.to
      }
      break
    case SET_TOGGLE_RECURR:
      if (!draft.config.scheduling) {
        draft.config.scheduling = DEFAULT_SCHEDULING_STATE
      }
      draft.config.scheduling.isRecurEnabled = action.enabled
      draft.config.scheduling.step = DEFAULT_SCHEDULING_STATE.step
      break
    case SET_TOGGLE_ROLL:
      if (!draft.config.scheduling) {
        draft.config.scheduling = DEFAULT_SCHEDULING_STATE
      }
      draft.config.scheduling.rollSegment = action.enabled
      break
    case SET_ROLL_INTERVAL:
      if (!draft.config.scheduling) {
        draft.config.scheduling = DEFAULT_SCHEDULING_STATE
      }
      draft.config.scheduling.rollInterval = action.value
      break
    case SET_ROLL_RECURR:
      if (!draft.config.scheduling) {
        draft.config.scheduling = DEFAULT_SCHEDULING_STATE
      }
      draft.config.scheduling.recur = action.value
      break
    case SET_ROLL_STEP:
      if (!draft.config.scheduling) {
        draft.config.scheduling = DEFAULT_SCHEDULING_STATE
      }
      draft.config.scheduling.step = action.value
      break
    case SET_AUTO_DISMISS_DURATION:
      draft.config.terminate_info.duration = action.value
      break
    case SET_AUTO_DISMISS_ENABLED:
      draft.config.terminate_info.auto_dismiss = action.value
      break
    case SET_GOAL_EVENT:
      draft.config.goal_event = action.event
      if (!action.event) {
        draft.config.terminate_info.enable_goal_events = false
      }
      break
    case SET_TERMINATE_INFO_ENABLE_GOAL_EVENTS:
      draft.config.terminate_info.enable_goal_events = action.enabled
      draft.config.frequency.count = -1
      draft.config.frequency.validity = "SESSION"
      draft.config.frequency.DSLimits = false
      delete draft.config.frequency.day_lmt
      delete draft.config.frequency.ses_lmt
      break
    case SET_ENABLE_TIME_BASED_TYPE:
      draft.config.terminate_info.enable_time_based = action.enabled
      draft.config.frequency.count = -1
      draft.config.frequency.validity = "SESSION"
      draft.config.frequency.DSLimits = false
      delete draft.config.frequency.day_lmt
      delete draft.config.frequency.ses_lmt
      break
    case SET_TIME_BASED_DAYS:
      draft.config.terminate_info.time_based = {
        ...(draft.config.terminate_info?.time_based ?? {}),
        days: action.value,
      }
      break
    case SET_ENABLE_TOUCH:
      draft.config.terminate_info.enable_touch = action.enabled
      draft.config.frequency.count = -1
      draft.config.frequency.validity = "SESSION"
      draft.config.frequency.DSLimits = false
      delete draft.config.frequency.day_lmt
      delete draft.config.frequency.ses_lmt
      break
    case SET_TERMINATE_INFO_ENABLE_ATTRIBUTES:
      draft.config.terminate_info.enable_attributes = action.enabled
      draft.config.frequency.count = -1
      draft.config.frequency.validity = "SESSION"
      draft.config.frequency.DSLimits = false
      delete draft.config.frequency.day_lmt
      delete draft.config.frequency.ses_lmt
      break
    case SET_TERMINATE_INFO_ATTRIBUTES_USER:
      draft.config.terminate_info.attributes = {
        ...(draft.config.terminate_info?.attributes ?? {}),
        user: [action.value],
      }
      break
    case SET_TERMINATE_INFO_GOAL_EVENTS_EVENTS:
      draft.config.terminate_info.goal_events = {
        ...(draft.config.terminate_info?.goal_events ?? {}),
        events: [action.value],
      }
      break
    case SET_TEST_SHOW_AT_SPECIFIC_TIME:
    case SET_TOGGLE_TIME_LIMITS_ENABLED:
      draft.config[getTimeLimitsEnabledKey(draft.config.meta.platform)] =
        action.enabled
      break
    case SET_TEST_TIME_LIMITS_END:
    case SET_TIME_LIMITS_END:
      draft.config.time_limits.end_time = action.end_hour_min
      break
    case SET_TEST_TIME_LIMITS_START:
    case SET_TIME_LIMITS_START:
      draft.config.time_limits.start_time = action.start_hour_min
      break
    case SET_TEST_START_DATE:
    case SET_VALIDITY_START:
      draft.config.validity.start_date = action.date_time
      if (!draft.config.scheduling) {
        draft.config.scheduling = DEFAULT_SCHEDULING_STATE
      }
      draft.config.scheduling.schedule_date = action.date
      draft.config.scheduling.schedule_time = action.time
      // draft.config.validity.end_date =
      //   draft.config.validity.end_date.slice(
      //     0,
      //     draft.config.validity.end_date.indexOf("T") + 1
      //   ) + action.date_time.slice(action.date_time.indexOf("T") + 1);
      break
    case SET_SCHEDULING_TIME:
      draft.config.scheduling.schedule_time = action.time
      break
    case SET_TEST_END_DATE:
    case SET_VALIDITY_END:
      draft.config.validity.end_date = action.date_time
      break
    case SET_TEST_DEVICES:
      draft.config.testing.devices = action.devices
      draft.config.testing.enabled = action.devices.length > 0
      break
    case SET_ENABLE_TESTING:
      draft.config.testing.enabled = action.enabled
      break
    case UPDATE_TEST_DEVICES:
      draft.config.testing.devices = action.devices
      break
    case REMOVE_TEST_DEVICE:
      if (draft.config.testing.devices.includes(action.device)) {
        const devices = draft.config.testing.devices
        draft.config.testing.devices = devices.filter(
          (d) => d !== action.device,
        )
      }
      break
    case SET_PRIORITY:
      draft.config.prio = action.value
      break
    case SET_TIME_SPENT:
    case SET_NTH_EVENT:
      draft.config.conditions = [...action.conditions]
      break
    case SET_CONTEXT_TYPE: {
      draft.config.c_type = action.index
      break
    }
    case SET_GOAL_MET_CHECK:
      draft.config.goal_met_check = !draft?.config?.goal_met_check
      break
    case ENABLE_CONDITIONS:
      if (action?.value) {
        draft.config.pre_cs = []
        draft.config.terminate_info.conditions = []
        draft.config.pre_cs_seq_enabled = false
        draft.config.terminate_info.sequence_enabled = false
        draft.config.goal_met_check = false
      } else {
        delete draft?.config?.pre_cs
        delete draft?.config?.terminate_info?.conditions
        delete draft?.config?.pre_cs_seq_enabled
        delete draft?.config?.terminate_info?.sequence_enabled
        delete draft?.config?.goal_met_check
      }
      break
    case SET_ADD_CONDITION: {
      const cs = get(draft?.config, action?.conditions_type?.cs, [])
      if (action?.is_goal_event) cs.unshift(action?.condition)
      else cs.push(action?.condition)
      break
    }
    case SET_DEMO_UPSERT_CONDITION:
      draft.config.conditions = [action.condition]
      break
    case SET_REMOVE_CONDITION: {
      const cs = get(draft?.config, action?.conditions_type?.cs, [])
      const sequence_enabled = get(
        draft?.config,
        action?.conditions_type?.se,
        false,
      )
      const updatedConditions = [
        ...cs?.slice(0, action.index),
        ...cs?.slice(action.index + 1, cs?.length),
      ]
      cs.splice(
        0,
        cs.length,
        ...updatedConditions?.map((condition, index) => ({
          ...condition,
          sequence: sequence_enabled ? index + 1 : -1,
        })),
      )
      break
    }
    case SET_UPDATE_CONDITION: {
      const cs = get(draft?.config, action?.conditions_type?.cs, [])
      cs[action.index] = { ...action?.condition }
      break
    }
    case SET_OPERATOR_AND_SEQUENCE_ENABLED: {
      const cs = get(draft?.config, action?.conditions_type?.cs, [])
      cs.splice(
        0,
        cs?.length,
        ...cs?.map((condition, index) => ({
          ...condition,
          combine_operator: action?.operator,
          sequence: action?.enabled ? index + 1 : -1,
        })),
      )
      set(draft?.config, action?.conditions_type?.se, action?.enabled)
      break
    }
    case SET_UI:
      draft.config.ui = action.ui
      break
    case SET_UIS:
      draft.config.uis = action.uis.filter(
        (eachUI) => !draft.uisToDelete.includes(eachUI.index),
      )
      break
    case SET_COHORTS:
      draft.cohorts = action.cohorts
      break
    case SET_DYNAMIC_COHORTS:
      draft.dynamic_cohorts = action.dynamic_cohorts
      break
    case SET_SEGMENTS:
      draft.segments = action.segments
      break
    case SET_DEFAULT_TEST_DEVICES:
      draft.test_devices = action.devices
      break
    case SET_CLIENT_EVENTS:
      draft.client_events = action.values
      break
    case SET_SCHEDULING_INSTANCE:
      draft.config.scheduling.schedule = action.value
      break
    case ADD_NEW_PROPERTY_IN_AUDIENCE:
      draft.config.audience.attributes[action.attr_key].push({
        name: "",
        operator: "EQ",
        t: "",
        tl: "",
        value: [],
      })
      break
    case SET_AUDIENCE_ATTRIBUTE:
      attrs = draft.config.audience.attributes[action.attr_key]
      if (attrs[action.index]) {
        attrs[action.index] = action.data
      }
      break
    case REMOVE_AUDIENCE_ATTRIBUTE:
      attrs = draft.config.audience.attributes[action.attr_key]
      attrs = attrs.filter((_, index) => index !== action.index)
      draft.config.audience.attributes[action.attr_key] = attrs
      break
    case SET_TOGGLE_APP_VERSION_FILTER:
      draft.config.audience.hasVer = action.enabled
      if (!action.enabled) {
        draft.config.audience.ver = undefined
      } else {
        draft.config.audience.ver = { op: "EQ", val: action.value }
      }
      break
    case SET_VERSION_OPERATOR:
      draft.config.audience.ver.op = action.value
      break
    case SET_VERSION_VALUE:
      if (draft.config.meta.platform === APP_PLATFORMS.android) {
        draft.config.audience.ver.build_number = action.value
        draft.config.audience.ver.val = action.value
      } else {
        draft.config.audience.ver.val = action.value
      }
      break
    case SET_TOGGLE_SESSION_ATTRIBUTES:
      draft.config.audience.attributes.hasSes = action.enabled
      if (!action.enabled) {
        draft.config.audience.attributes.session = []
      }
      break
    case SET_TOGGLE_USER_ATTRIBUTES:
      draft.config.audience.attributes.hasUser = action.enabled
      if (!action.enabled) {
        draft.config.audience.attributes.user = []
      }
      break
    case SET_TARGET_ANONYMOUS_USERS_ONLY:
      if (action.enabled && draft.config.audience.exclude_anonymous) {
        draft.config.audience.exclude_anonymous =
          !draft.config.audience.exclude_anonymous
      }
      draft.config.audience.only_anonymous = action.enabled
      break
    case SET_EXCLUDE_ANONYMOUS_USERS:
      if (action.enabled && draft.config.audience.only_anonymous) {
        draft.config.audience.only_anonymous =
          !draft.config.audience.only_anonymous
      }
      draft.config.audience.exclude_anonymous = action.enabled
      break
    case SET_SELECTED_TEST_DEVICE:
      const { value, label } = action.value
      draft.config.testing.selected = value
      draft.config.testing.selectedLabel = label
      if (
        draft.config.testing.devices &&
        !draft.config.testing.devices.includes(value)
      ) {
        draft.config.testing.devices.push(value)
      } else {
        draft.config.testing.devices = [value]
      }
      break
    case SET_SNACKBAR_ERROR_MESSAGE:
      draft.snackbarMessage = action.value
      break
    case SET_EXPERIMENT:
      draft.config.meta.isExperiment = true
      draft.config.meta.category = "AB-test"
      draft.config.meta.tags =
        draft?.config?.meta?.tags?.length > 0
          ? ["A/B", ...draft.config.meta.tags]
          : ["A/B"]
      draft.config.experiment = {
        currentVariantStep: 0,
        variants: {
          A: {
            name: action.name,
            indices: action.payload || [],
            distribution: 49,
          },
          CG: {
            name: "Control Group",
            indices: [],
            distribution: 2,
          },
        },
      }
      draft.config.experiment.publishSettings = {
        CG: 3,
        time_settings: {
          start_date: moment().toISOString(),
          end_date: moment().add("days", 7).toISOString(),
          time_limits_in_day: false,
          time_limits: {
            start_time: "10:00",
            end_time: "20:30",
          },
        },
      }
      draft.config.experiment.settings = {
        isManualExperiment: false,
        expectedLoss: 0.0015,
        bestProbability: 0.95,
        targetGroup: 10,
      }
      break
    case ADD_VARIANT:
      const variantKey = String.fromCharCode(
        Object.keys(draft.config.experiment.variants).length + 64,
      )
      draft.config.experiment.variants[variantKey] = {
        name: action.value,
        indices: [],
        distribution: 49,
      }
      break
    case SET_VARIANT_PERCENTAGE:
      draft.config.experiment.variants[action.variantName].distribution =
        action.value
      break
    case SET_TARGET_PERCENTAGE:
      draft.config.experiment.settings.targetGroup = action.value
      break
    case SET_AUTO_PUBLISH_WINNER:
      draft.config.experiment.settings.isManualExperiment = action.value
      break
    case SET_CURRENT_VARIANT_STEP:
      draft.config.experiment.currentVariantStep = action.value
      break
    case DELETE_VARIANT:
      draft.uisToDelete.push(...action.uiIndicesToDelete)
      draft.config.uis = draft.config.uis.filter(
        (eachUI) => !action.uiIndicesToDelete.includes(eachUI.index),
      )
      delete draft.config.experiment.variants[action.key]
      draft.config.experiment.currentVariantStep =
        action.value - 1 >= 0 ? action.value - 1 : 0

      if (
        Object.keys(draft.config.experiment.variants).filter(
          (key) => key !== "CG",
        ).length === 0
      ) {
        draft.config.meta.isExperiment = false
        draft.config.meta.tags = draft.config.meta.tags.filter(
          (eachTag) => eachTag !== "A/B",
        )
        draft.config.meta.category && delete draft.config.meta.category
      } else {
        Object.keys(draft.config.experiment.variants)
          .filter((key) => key !== "CG")
          .sort()
          .forEach((variantKey, index) => {
            if (String.fromCharCode(index + 65) !== variantKey) {
              draft.config.experiment.variants[
                String.fromCharCode(index + 65)
              ] = draft.config.experiment.variants[variantKey]
              delete draft.config.experiment.variants[variantKey]
            }
          })
      }
      break
    case ADD_STEP_TO_VARIANT:
      draft.config.experiment.variants[action.step].indices.push(action.index)
      break
    case SET_SELECTED_TEST_VARIANT:
      draft.config.testing.selectedVariant = action.value
      break
    case DELETE_STEP_FROM_VARIANT:
      const index = draft.config.experiment.variants[
        action.currentVariantKey
      ].indices.indexOf(action.index)
      if (index > -1) {
        draft.config.experiment.variants[
          action.currentVariantKey
        ].indices.splice(index, 1)
      }
      break
    case ON_COPY_STEP_VARIANT:
      draft.config.experiment.variants[action.currentVariantKey].indices.push(
        action.index,
      )
      break
    case SET_PUBLISH_START_DATE:
      draft.config.experiment.publishSettings.time_settings.start_date =
        action.date_time
      break
    case SET_PUBLISH_END_DATE:
      draft.config.experiment.publishSettings.time_settings.end_date =
        action.date_time
      break
    case SET_PUBLISH_SHOW_AT_SPECIFIC_TIME:
      draft.config.experiment.publishSettings.time_settings.time_limits_in_day =
        action.enabled
      break
    case SET_PUBLISH_TIME_LIMITS_START:
      draft.config.experiment.publishSettings.time_settings.time_limits.start_time =
        action.start_hour_min
      break
    case SET_PUBLISH_TIME_LIMITS_END:
      draft.config.experiment.publishSettings.time_settings.time_limits.end_time =
        action.end_hour_min
      break
    case SET_CAMPAIGN_PUBLISH_CG:
      if (action.isExperiment)
        draft.config.experiment.publishSettings.CG = action.value
      else draft.config.cg_percentage = action.value
      break
    case SET_SURVEY_DELAY:
      draft.config.ui.delay = action.value
      break
    default:
      return draft
  }
})
