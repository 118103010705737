import { Button, ButtonBase, Typography } from "@material-ui/core"
import Radio from "@material-ui/core/Radio"
import { withStyles } from "@material-ui/core/styles"
import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import { useAuth } from "../../../../../../contexts/AuthContext"
import { capitalizeEachWord } from "../../../../../../utils"
import useApxorClient from "../../../../../../utils/use-apxor-client"
import useDebounced from "../../../../../../utils/use-debounced"
import { useDeepCompareCallback } from "../../../../../../utils/use-deep-compare"
import {
  PLATFORM_IMAGES,
  getPlatformLogoColor,
} from "../../../../../App/styling/CustomCSS"
import { WebLogo } from "../../../../../App/styling/Logos"
import { apxTheme } from "../../../../../ApplicationFrame/styling/CustomCSS"
import { useTrackedState } from "../../../../../IntegrationExperience/store"
import useUser from "../../../../../Profile/hooks/use-user"
import { StyledInputLabel } from "../../../../../Profile/pages/ProfilePage"
import ActionsTextField from "../../../../../ReusableComponents/ActionsTextField"
import ClassicCard from "../../../../../ReusableComponents/ClassicCard"
import HStack from "../../../../../ReusableComponents/HStack"
import Loading from "../../../../../ReusableComponents/Loading"
import ModalPopup from "../../../../../ReusableComponents/ModalPopup"
import VStack from "../../../../../ReusableComponents/VStack"
import { FEATURES_LIST_ENUM } from "../../../../components/settings/general/constants"
import android from "../../assets/android.png"
import ios from "../../assets/ios.png"
import web from "../../assets/web.png"
import { checkIfCampaignNameExistsAPI } from "../actions"
import { SET_META_PLATFORM, SET_SURVEY_TYPE } from "../constants"
import { useTracked } from "../store"

export const APP_PLATFORMS = {
  ios: "ios",
  android: "android",
  web: "web",
}

const OMNI_APP_PLATFORMS = Object.freeze({
  android: "android",
  ios: "ios",
  web: "web",
  m_web: "mWeb",
})

const WEB_APP_PLATFORMS = Object.freeze({
  web: "web",
  m_web: "mWeb",
})

export const APP_PLATFORM_NAMES = {
  ios: "iOS",
  android: "Android",
  web: "Web",
  m_web: "Mobile Web",
}

export const APP_PLATFORM_IMAGES = {
  ios: (
    <img
      alt=""
      style={{ height: 29, width: 24, marginBottom: -4 }}
      src={ios}
    />
  ),
  android: (
    <img
      alt=""
      style={{ height: 30, width: 28, marginBottom: -4 }}
      src={android}
    />
  ),
  web: (
    <img
      alt=""
      style={{ height: 36, width: 36, marginBottom: -4 }}
      src={web}
    />
  ),
  m_web: (
    <img
      alt=""
      style={{ height: 36, width: 36, marginBottom: -4 }}
      src={web}
    />
  ),
}

const ORadio = withStyles({
  root: {
    "&$checked": {
      color: "#FF7F33",
    },
  },
  checked: {},
})((props) => (
  <Radio
    color="default"
    {...props}
  />
))

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: "3em",
    primary: "orange",
    boxShadow: "none",
    border: `1px solid ${apxTheme.palette.action.focus}`,
    "&:hover": { borderColor: "none" },
    minWidth: 360,
    padding: 6,
    fontWeight: 600,
    fontSize: 15,
    color: "rgba(0, 40, 69)",
    borderWidth: "1px",
    borderRadius: 12,
  }),
  "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
    backgroundColor: "transparent",
  },
  placeholder: (base) => ({
    ...base,
    color: "#BDBDBD",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#F8F8F8" : "transparent",
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
    cursor: "pointer",
    fontSize: 15,
    fontWeight: 600,
    borderRadius: 12,
    padding: 12,
    zIndex: 50,
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 12,
    marginTop: 6,
    border: `1px solid ${apxTheme.palette.action.focus}`,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "rgba(0, 40, 69, 0.1)",
    borderRadius: 8,
    padding: "0 6px",
    color: "rgba(0, 40, 69, 0.6)",
  }),
  multiValueRemove: (base) => ({
    ...base,
    borderRadius: "50%",
    color: apxTheme.palette.action.disabled,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "rgba(229, 59, 59, 1)",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(0, 40, 69, 1)",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(229, 59, 59, 1)",
    },
  }),
}

export const customStylesSingleSelect = {
  control: (base, state) => ({
    ...base,
    minHeight: "3em",
    primary: "orange",
    boxShadow: "none",
    border: `1px solid ${apxTheme.palette.action.focus}`,
    "&:hover": { borderColor: "none" },
    minWidth: 360,
    padding: 6,
    fontWeight: 600,
    fontSize: 15,
    borderWidth: "1px",
    borderRadius: 12,
    backgroundColor: state.isFocused
      ? state.isSelected
        ? apxTheme.palette.grey[200]
        : "#F8F8F8"
      : "transparent",
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  placeholder: (base) => ({
    ...base,
    color: "#BDBDBD",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#F8F8F8" : "transparent",
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
    cursor: "pointer",
    fontSize: 15,
    fontWeight: 600,
    borderRadius: 12,
    padding: 12,
    zIndex: 50,
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 12,
    marginTop: 6,
    border: `1px solid ${apxTheme.palette.action.focus}`,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(0, 40, 69, 1)",
    },
  }),
}

function getDefaultPlatform(appPlatform, metaPlatform) {
  if (appPlatform === APP_PLATFORMS.ios) return APP_PLATFORMS.ios
  else if (appPlatform === APP_PLATFORMS.web) return APP_PLATFORMS.web
  else return metaPlatform
}
const surveyTypes = ["latest", "legacy"]

export default function CreateEditCampaignDialog({
  open,
  onClose,
  handleDone,
  metaPlatform,
  appPlatform,
  handleCancel,
  task,
  tagNames,
  loading,
  title,
}) {
  const { logEvent } = useApxorClient()
  const auth = useAuth()
  const { appId } = useParams()
  const [state, dispatch] = useTracked()
  const {
    config: {
      meta: { name, tags = [], config_type = "walkthrough" },
      surveyType = "legacy",
    },
  } = state

  const {
    app_data: {
      basic_info: { platform = APP_PLATFORMS.android },
      features = [],
    },
  } = useTrackedState()

  const [campaignName, setCampaignName] = useState(name)
  const [selectedPlatform, setSelectedPlatform] = useState(
    getDefaultPlatform(appPlatform, metaPlatform),
  )
  const [tagValue, setTagValue] = useState(tags ?? [])
  const [selectedSurveyType, setSelectedSurveyType] = useState(
    task === "Create" ? "legacy" : surveyType,
  )
  const [type, setType] = useState({
    label: config_type === "walkthrough" ? "Walkthrough" : "Screen Explainer",
    value: config_type,
  })
  const [error, setError] = useState("")

  const isFeatureEnabled = useDeepCompareCallback(
    (feature) => features.includes(feature),
    [features],
  )

  const checkName = useDeepCompareCallback(
    async (n) => {
      const nameExists = await checkIfCampaignNameExistsAPI(
        auth,
        appId,
        { configType: title === "Campaign" ? "walkthrough" : "survey" },
        { name: n },
      )
      if (nameExists && n !== name) {
        setError(
          `A ${
            title === "Campaign" ? "campaign" : "survey"
          } with this name already exists.`,
        )
      }
    },
    [appId, auth, title],
  )

  const { currentRefinedUser } = useUser()

  const handleNameChange = useDebounced(async (event) => {
    setCampaignName(event.target.value)
    await checkName(event.target.value)
  }, 700)

  useEffect(() => {
    ;(async () => {
      if (name) {
        await checkName(name)
      }
    })()
  }, [name, checkName])

  const dialogTitle = useMemo(() => `${task} ${title}`, [title, task])

  const platforms = useMemo(
    () =>
      getDefaultPlatform(appPlatform, metaPlatform) === APP_PLATFORMS.web
        ? WEB_APP_PLATFORMS
        : OMNI_APP_PLATFORMS,
    [appPlatform, metaPlatform],
  )

  return (
    <ModalPopup
      open={open}
      onClose={onClose}
      title={dialogTitle}
      maxWidth="900px"
      disableCloseByOtherMethods={task !== "Update"}
    >
      <VStack spacing="70px">
        {/* Form Elements */}
        <VStack
          spacing="20px"
          style={{
            flex: 1,
          }}
        >
          {/* Name */}
          <VStack spacing="8px">
            <StyledInputLabel htmlFor="c_name">Name</StyledInputLabel>
            <VStack>
              <ActionsTextField
                id="c_name"
                autoFocus
                name="name"
                value={campaignName}
                onChange={(e) => {
                  if (error.length > 0) {
                    setError("")
                  }
                  handleNameChange(e)
                }}
                style={{ marginBottom: 0 }}
                InputProps={{
                  style: {
                    borderColor: error
                      ? "rgba(229, 59, 59, 1)"
                      : apxTheme.palette.action.focus,
                    minWidth: 360,
                    padding: 6,
                    fontWeight: 600,
                    fontSize: 15,
                    color: "rgba(0, 40, 69)",
                    borderWidth: error ? "1.5px" : "1px",
                    borderRadius: 12,
                  },
                }}
              />
              {error.length !== 0 && (
                <Typography
                  style={{
                    color: "rgba(229, 59, 59, 1)",
                    fontSize: 14,
                    fontWeight: 600,
                    marginTop: -8,
                    paddingInlineStart: 4,
                  }}
                >
                  {error}
                </Typography>
              )}
            </VStack>
          </VStack>

          {/* Platform */}
          {((appPlatform === "omni" && task !== "Update") ||
            (appPlatform === "web" &&
              task !== "Update" &&
              task !== "Duplicate")) && (
            <VStack spacing="8px">
              <StyledInputLabel htmlFor="platform">Platform</StyledInputLabel>
              <HStack spacing="15px">
                {Object.keys(platforms).map((platformKey, idx) => (
                  <ButtonBase
                    key={idx}
                    style={{
                      minWidth: "180px",
                      maxWidth: "250px",
                    }}
                    disableRipple
                    disableTouchRipple
                    onClick={() => {
                      setSelectedPlatform(platformKey)
                      dispatch({
                        type: SET_META_PLATFORM,
                        payload: platformKey === "m_web" ? "web" : platformKey,
                      })
                    }}
                  >
                    <ClassicCard
                      style={{
                        boxShadow: "none",
                        borderWidth: 1.5,
                        borderRadius: 12,
                        borderColor:
                          selectedPlatform === platformKey
                            ? apxTheme.palette.primary.main
                            : apxTheme.palette.action.focus,
                      }}
                      selected={selectedPlatform === platformKey}
                    >
                      <HStack
                        spacing="2px"
                        justify="space-evenly"
                      >
                        <ORadio checked={selectedPlatform === platformKey} />
                        {platformKey === "m_web" ? (
                          <WebLogo
                            color={"#3DDC84"}
                            width={24}
                          />
                        ) : (
                          PLATFORM_IMAGES(
                            platformKey,
                            getPlatformLogoColor(platformKey),
                            24,
                          )
                        )}
                        <Typography
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                          }}
                        >
                          {APP_PLATFORM_NAMES[platformKey]}
                        </Typography>
                      </HStack>
                    </ClassicCard>
                  </ButtonBase>
                ))}
              </HStack>
            </VStack>
          )}

          {/* Tags */}
          <VStack spacing="8px">
            <StyledInputLabel htmlFor="tags">Tags</StyledInputLabel>
            <CreatableSelect
              id="tags"
              placeholder="Categorise your campaigns based on tags, ex: 'offers', 'services'"
              noOptionsMessage={() => "No tags found"}
              isLoading={loading}
              maxMenuHeight={120}
              closeMenuOnSelect={false}
              isClearable
              options={tagNames.map((o) => ({
                label: o,
                value: o,
              }))}
              isMulti
              onChange={(e) => {
                setTagValue(!e ? [] : e.map((tag) => tag.label))
              }}
              value={tagValue.map((tag) => ({
                label: tag,
                value: tag,
              }))}
              styles={customStyles}
              formatCreateLabel={() => {
                return (
                  <div
                    style={{
                      fontWeight: 700,
                      fontSize: 15,
                      color: apxTheme.palette.success.light,
                    }}
                  >
                    + Add & Select this Tag
                  </div>
                )
              }}
            />
          </VStack>

          {/* Type of Survey (Latest / Legacy) */}
          {title === "Survey" &&
            task === "Create" &&
            isFeatureEnabled(
              FEATURES_LIST_ENUM.NUDGES.children.NEW_SURVEYS.value,
            ) &&
            currentRefinedUser?.flags?.includes(
              FEATURES_LIST_ENUM.NUDGES.children.NEW_SURVEYS.value,
            ) && (
              <VStack spacing="8px">
                <StyledInputLabel htmlFor="tags">Survey Type</StyledInputLabel>
                <Select
                  id="font-family"
                  placeholder="Select Survey Type"
                  closeMenuOnSelect={true}
                  isClearable={false}
                  isSearchable={false}
                  options={surveyTypes.map((option) => ({
                    label: capitalizeEachWord(option),
                    value: option,
                  }))}
                  isMulti={false}
                  onChange={(selected) => {
                    setSelectedSurveyType(selected.value)
                    dispatch({
                      type: SET_SURVEY_TYPE,
                      value: selected.value,
                    })
                  }}
                  value={{
                    label: capitalizeEachWord(selectedSurveyType),
                    value: selectedSurveyType,
                  }}
                  styles={customStyles}
                />
              </VStack>
            )}

          {/* Type of Campaign (Explainer / Walkthrough) : ANDROID ONLY */}
          {title !== "Survey" &&
            task === "Create" &&
            (platform === APP_PLATFORMS.android ||
              selectedPlatform === APP_PLATFORMS.android) &&
            isFeatureEnabled(
              FEATURES_LIST_ENUM.NUDGES.children.SCREEN_EXPLAINER.value,
            ) &&
            currentRefinedUser?.flags?.includes(
              FEATURES_LIST_ENUM.NUDGES.children.SCREEN_EXPLAINER.value,
            ) && (
              <VStack spacing="8px">
                <StyledInputLabel htmlFor="type">
                  What are you planning to create?
                </StyledInputLabel>
                <Select
                  id="type"
                  placeholder="Select the type of campaign"
                  noOptionsMessage={() => "Please select a type of campaign"}
                  isLoading={loading}
                  maxMenuHeight={120}
                  closeMenuOnSelect={true}
                  isClearable={false}
                  options={[
                    {
                      label: "Walkthrough",
                      value: "walkthrough",
                    },
                    {
                      label: "Screen Explainer",
                      value: "onboarding",
                    },
                  ]}
                  isMulti={false}
                  onChange={(selected) => {
                    setType(selected)
                  }}
                  value={type}
                  styles={customStylesSingleSelect}
                />
              </VStack>
            )}
        </VStack>

        {/* Form Actions */}
        <HStack
          spacing="30px"
          justify="end"
          style={{
            paddingBottom: "20px",
          }}
        >
          {/* Cancel Button */}
          <Button
            size="large"
            variant="text"
            color="default"
            onClick={handleCancel}
          >
            Cancel
          </Button>

          <Button
            id="campaign-create-button"
            size="large"
            type="submit"
            style={{ width: "140px", height: "44px" }}
            variant="contained"
            color="primary"
            disabled={
              selectedPlatform === null ||
              campaignName === "" ||
              error.length > 0 ||
              loading
            }
            onClick={() => {
              handleDone(
                campaignName,
                selectedPlatform,
                tagValue,
                selectedSurveyType,
                title === "Survey" ? config_type : type.value,
              )
              logEvent("DesignPageLaunched", { source: "Creation" })
              if (task === "Create") {
                title === "Survey"
                  ? logEvent("CreateSurvey_Popup_CreateClicked", {
                      survey_name: campaignName,
                      survey_tag: tagValue,
                    })
                  : logEvent("CreateCampaign_Popup_CreateClicked", {
                      campaign_name: campaignName,
                      campaign_tag: tagValue,
                    })
              }
              if (task === "Update") {
                title === "Survey"
                  ? logEvent("UpdateSurvey_Popup_UpdateClicked", {
                      survey_name: campaignName,
                      survey_tag: tagValue,
                    })
                  : logEvent("UpdateCampaign_Popup_UpdateClicked", {
                      campaign_name: campaignName,
                      campaign_tag: tagValue,
                    })
              }
            }}
          >
            {loading ? (
              <Loading
                size={28}
                color="#FFF"
              />
            ) : (
              task
            )}
          </Button>
        </HStack>
      </VStack>
    </ModalPopup>
  )
}
