import {
  Box,
  Button,
  IconButton,
  Menu,
  Typography,
  withStyles,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/CreateOutlined"
import DeleteIcon from "@material-ui/icons/DeleteOutlined"
import CopyIcon from "@material-ui/icons/FileCopyOutlined"
import MoreIcon from "@material-ui/icons/MoreVert"
import { Suspense, lazy, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { isSuper } from "../../../config"
import { useAuth } from "../../../contexts/AuthContext"
import { copyToClipboard } from "../../../utils"
import useApxorClient from "../../../utils/use-apxor-client"
import { useDeepCompareMemo } from "../../../utils/use-deep-compare"
import { ORG_DIALOG_STATES_ENUM } from "../../App/constants"
import { apxTheme } from "../../ApplicationFrame/styling/CustomCSS"
import { ROLES_ENUM } from "../../Dashboard/components/settings/constants"
import HStack from "../../ReusableComponents/HStack"
import Snackbar from "../../ReusableComponents/Snackbar"
import VStack from "../../ReusableComponents/VStack"
import WhitePaper from "../../ReusableComponents/WhitePaper"
import DialogLoader from "../../ReusableComponents/skeletons/DialogLoader"
import { useTrackedState } from "../store"

const EditOrgDetailsDialog = lazy(() => import("./EditOrgDetailsDialog"))
const DeleteOrgDialog = lazy(() => import("./DeleteOrgDialog"))

export const StyledOrgCard = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "space-between",
    padding: "20px 25px",
    minHeight: "220px",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      boxShadow: "0px 1px 40px rgba(181, 202, 224, 0.4)",
    },
    cursor: "pointer",
  },
})(Box)

export const StyledMenu = withStyles({
  paper: {
    paddingBlock: "6px",
    paddingInline: "10px",
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: apxTheme.shadows[10],
  },
  list: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
})(Menu)

export const StyledMenuButton = withStyles({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    paddingBlock: "6px",
    paddingInline: "8px",
    borderLeft: "none",
    "&:hover": {
      backgroundColor: apxTheme.palette.primary.almostInvisible,
    },
  },
})(Button)

const StyledSkeletonBox = withStyles({
  "@keyframes pulse": {
    "50%": {
      opacity: "0.55",
    },
  },
  root: {
    animation: "$pulse 1.4s cubic-bezier(0.4, 0, 0.6, 1) infinite",
  },
})(Box)

export function OrgCardSkeleton() {
  return (
    <StyledOrgCard component={WhitePaper}>
      <HStack spacing="16px">
        <StyledSkeletonBox
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#E0E0E0",
          }}
        ></StyledSkeletonBox>
        <StyledSkeletonBox
          style={{
            backgroundColor: "#E0E0E0",
            padding: "10px",
            borderRadius: "3px",
            width: "100px",
          }}
        ></StyledSkeletonBox>
      </HStack>
      <HStack
        align="start"
        spacing="40px"
      >
        {[...Array(2)].map((_, idx) => (
          <VStack
            spacing="6px"
            key={idx}
          >
            <StyledSkeletonBox
              style={{
                backgroundColor: "#E0E0E0",
                padding: "10px",
                borderRadius: "3px",
                width: "26px",
              }}
            ></StyledSkeletonBox>
            <StyledSkeletonBox
              style={{
                backgroundColor: "#E0E0E0",
                padding: "15px 10px",
                borderRadius: "3px",
                width: "60px",
              }}
            ></StyledSkeletonBox>
          </VStack>
        ))}
      </HStack>
    </StyledOrgCard>
  )
}

export default function OrgCard({ org, setOrgs }) {
  const { push } = useHistory()
  const [cardHovered, setCardHovered] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const { org_loading, org_data } = useTrackedState()

  const [dialogOpen, setDialogOpen] = useState(ORG_DIALOG_STATES_ENUM.CLOSE)

  const [snackBarMessage, setSnackBarMessage] = useState("")

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const { user } = useAuth()

  const currentUserFromOrgState = useDeepCompareMemo(() => {
    return {
      customer_id: user?.email,
      ...((isSuper(user?.email) || user?.email === org_data?.owner) && {
        role: ROLES_ENUM.ORG_OWNER.value,
      }),
    }
  }, [org_data, user])

  const userHasAccessToMutateOrg = useMemo(() => {
    return currentUserFromOrgState?.role === ROLES_ENUM.ORG_OWNER.value
  }, [currentUserFromOrgState.role])

  const { logEvent } = useApxorClient()

  useEffect(() => {
    const handleMouseOut = (event) => {
      if (
        event.clientY <= 0 ||
        event.clientX <= 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      ) {
        handleClose(event)
      }
    }
    window.addEventListener("mouseout", handleMouseOut)

    return () => {
      window.removeEventListener("mouseout", handleMouseOut)
    }
  }, [])

  return (
    <>
      {!org_loading && snackBarMessage !== "" && (
        <Snackbar
          onClose={() => {
            setSnackBarMessage("")
          }}
        >
          <strong>{snackBarMessage}</strong>
        </Snackbar>
      )}
      <StyledOrgCard
        component={WhitePaper}
        style={{
          position: "relative",
        }}
        onClick={() => {
          push(`/orgs/${org?._id}/apps`)
          logEvent("Apps_PageLaunched", { source: "Orgs-page" })
        }}
        onMouseEnter={() => {
          setCardHovered(true)
        }}
        onMouseLeave={() => {
          setCardHovered(false)
        }}
      >
        {cardHovered && (
          <>
            <IconButton
              style={{
                position: "absolute",
                top: "12px",
                right: "8px",
              }}
              onClick={(e) => {
                e.stopPropagation()
                handleClick(e)
              }}
            >
              <MoreIcon
                fontSize="small"
                color="action"
              />
            </IconButton>
            <StyledMenu
              elevation={0}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuButton
                onClick={(e) => {
                  copyToClipboard(org?._id)
                  handleClose(e)
                  setSnackBarMessage("Org ID copied to clipboard successfully!")
                }}
                startIcon={
                  <CopyIcon
                    color="action"
                    style={{
                      width: "16px",
                      height: "16px",
                    }}
                  />
                }
              >
                Copy Org ID
              </StyledMenuButton>
              {userHasAccessToMutateOrg && (
                <StyledMenuButton
                  onClick={(e) => {
                    setDialogOpen(ORG_DIALOG_STATES_ENUM.EDIT)
                    handleClose(e)
                  }}
                  startIcon={
                    <EditIcon
                      color="primary"
                      style={{
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  }
                >
                  Edit Org Details
                </StyledMenuButton>
              )}
              {userHasAccessToMutateOrg && (
                <StyledMenuButton
                  onClick={(e) => {
                    setDialogOpen(ORG_DIALOG_STATES_ENUM.DELETE)
                    handleClose(e)
                  }}
                  startIcon={
                    <DeleteIcon
                      color="error"
                      style={{
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  }
                >
                  Delete Org
                </StyledMenuButton>
              )}
            </StyledMenu>
          </>
        )}
        <HStack
          align="start"
          spacing="16px"
        >
          <Box
            style={{
              maxWidth: "60px",
            }}
          >
            <img
              src={org?.icon_url}
              height="60px"
              width="60px"
              alt=""
            />
          </Box>
          <Typography
            style={{
              color: "#002845",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            {org?.name}
          </Typography>
        </HStack>
        <HStack
          spacing="40px"
          align="start"
        >
          <VStack spacing="16px">
            <Typography
              style={{
                fontSize: "12px",
                fontWeight: "400",
                color: "rgba(0, 40, 69, 0.7)",
              }}
            >
              Admin
            </Typography>
            <Typography
              styles={{
                color: "#002845",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              {org?.owner}
            </Typography>
          </VStack>
        </HStack>
      </StyledOrgCard>
      {dialogOpen === ORG_DIALOG_STATES_ENUM.EDIT && (
        <Suspense fallback={<DialogLoader />}>
          <EditOrgDetailsDialog
            open={dialogOpen === ORG_DIALOG_STATES_ENUM.EDIT}
            onClose={() => {
              setDialogOpen(ORG_DIALOG_STATES_ENUM.CLOSE)
            }}
            org={org}
            setOrgs={setOrgs}
            setSnackBarMessage={setSnackBarMessage}
          />
        </Suspense>
      )}
      {dialogOpen === ORG_DIALOG_STATES_ENUM.DELETE && (
        <Suspense fallback={<DialogLoader />}>
          <DeleteOrgDialog
            open={dialogOpen === ORG_DIALOG_STATES_ENUM.DELETE}
            onClose={() => {
              setDialogOpen(ORG_DIALOG_STATES_ENUM.CLOSE)
            }}
            org={org}
            setOrgs={setOrgs}
            setSnackBarMessage={setSnackBarMessage}
          />
        </Suspense>
      )}
    </>
  )
}
