import { TextField, Typography } from "@material-ui/core"
import PropTypes from "prop-types"
import { useEffect, useMemo, useRef, useState } from "react"

export default function ActionsTextField({
  style,
  type,
  disabled = false,
  focused = false,
  value = "",
  withPropertySelector = false,
  inputAdornments,
  InputProps = {},
  isOtherUsage = false,
  multiline = type !== "number",
  placeholder = "",
  userProperties = [],
  sessionProperties = [],
  onChange, // TODO: find a better fix, we simply don't want onChange to end up in otherProps.
  onSelect,
  textFieldStyle = { minHeight: 20 },
  ...otherProps
}) {
  const [fieldValue, setFieldValue] = useState("")
  const inputRef = useRef()

  const selectorProps = useMemo(
    () => (withPropertySelector && inputAdornments ? inputAdornments : {}),
    [withPropertySelector, inputAdornments],
  )

  const onValueChange = (event) => {
    setFieldValue(event.target.value)
    onChange(event)
  }

  useEffect(() => {
    if (inputRef && focused) {
      inputRef.current.focus()
    }
  }, [focused])

  useEffect(() => {
    if (value !== fieldValue) {
      setFieldValue(Number.isInteger(value) ? value : value || "")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <div style={{ marginBottom: 24, ...style }}>
      <TextField
        disabled={disabled}
        inputRef={inputRef}
        inputProps={{
          style: { padding: 8, ...textFieldStyle },
          maxLength: otherProps.maxLength,
          ...InputProps,
        }}
        InputProps={{
          // style: { padding: 3 },
          ...InputProps,
          ...selectorProps,
        }}
        fullWidth
        multiline={multiline}
        type={type}
        margin="none"
        variant="standard"
        style={{ margin: 0 }}
        required={otherProps.required}
        value={fieldValue}
        onChange={onValueChange}
        onSelect={(e) => onSelect && onSelect(e.target.selectionStart)}
        placeholder={placeholder}
        {...otherProps}
      />
      {withPropertySelector && !isOtherUsage && (
        <Typography
          variant={"body2"}
          style={{ padding: "2px 4px" }}
        >
          Note: Defaults for Macros supports only Alpha Numeric string
        </Typography>
      )}
      {otherProps.validationErrors && (
        <Typography
          variant={"body2"}
          style={
            otherProps?.validationErrorStyles
              ? otherProps?.validationErrorStyles
              : { padding: "2px 4px", color: "red" }
          }
        >
          {otherProps.validationErrors}
        </Typography>
      )}
    </div>
  )
}

ActionsTextField.propTypes = {
  placeholder: PropTypes.string,
}
