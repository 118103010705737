import axios from "axios"
import { API_BASE, API_ROOT } from "../config"
import Logger from "../utils/Logger"
import Cookie from "../utils/Storage/Cookie"

export const HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "x-apx-host": window.location.hostname,
}

const EXTEND_API = "authenticate/extend"

const defaultConfig = {
  headers: HEADERS,
}

// for multiple requests
let isRefreshing = false
let failedQueue = []
let cancelTokenStore = {}

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
  isRefreshing = false
}

const makeConfig = (config) => {
  return {
    headers: {
      ...defaultConfig.headers,
      ...config.customHeaders,
      "Access-Control-Allow-Credentials": true,
    },
    data: config.body,
    method: config.method,
    withCredentials: true,
  }
}

/**
 * checks whether a value is defined
 * @param value {*}
 * @param strict {Boolean}
 * @returns {Boolean}
 */
export function isDefined(value, strict = true) {
  if (!strict && (value === 0 || value === "")) return true //FIXME: handling 0 values
  return value && value !== null && typeof value !== "undefined"
}

/**
 * checks whether a property exists and if the value is defined
 * @param object {Object}
 * @param property {String}
 * @returns {Boolean}
 */
export function isPropertyDefined(object, property) {
  return object.hasOwnProperty(property) && isDefined(object[property])
}

/**
 *
 * @param resolve
 * @param callback
 * @param result
 * @returns {*}
 */
const handleSuccess = (resolve, callback, result) => {
  if (isDefined(callback) && typeof callback === "function") {
    return resolve(callback(result))
  } else {
    return resolve(result)
  }
}

/**
 *
 * @param reject
 * @param callback
 * @param error
 * @returns {*}
 */
const handleFailure = (reject, callback, error) => {
  if (isDefined(callback) && typeof callback === "function") {
    return reject(callback(error))
  } else {
    return reject(error)
  }
}

const axiosInstance = axios.create({
  baseURL: API_BASE,
  headers: {
    ...defaultConfig.headers,
  },
})

function getUserEmail() {
  const cookie = new Cookie(window, Logger)
  const sessionFromCookie = JSON.parse(
    atob(cookie.get("_apx_n2_") || "") || "{}",
  )
  const auth = sessionFromCookie.user
  return auth && auth.email ? auth.email : null
}

axiosInstance.interceptors.response.use(
  (response) => {
    // For cancelled requests, we don't need to do anything, since we're the ones who cancelled them in the first place, using an AbortController.
    if (axios.isCancel(response)) {
      return
    }
    return response
  },
  (error) => {
    // For cancelled requests, we don't need to do anything, since we're the ones who cancelled them in the first place, using an AbortController.
    if (axios.isCancel(error)) {
      return
    }

    const originalRequest = error.config

    const isLoginOrResetPage =
      originalRequest.url.indexOf("/authenticate/customer") !== -1 ||
      originalRequest.url.indexOf("/reset-password/customer") !== -1

    if (isLoginOrResetPage) {
      return Promise.reject(error.response)
    }

    const isExtendAPI =
      originalRequest.url.indexOf("/authenticate/extend") !== -1

    // If the EXTEND_API returns 401/403 too, forcefully redirect the user to logout page.
    if (
      (error.response.status === 403 || error.response.status === 401) &&
      isExtendAPI
    ) {
      window.open("/logout", "_self", true)
      failedQueue = []

      return Promise.resolve(error.response.data)
    }

    // Add the failed 401 requests to the queue, which will be processed later after the EXTEND_API resolves successfully.
    if (error.response.status === 401 && !originalRequest._retry) {
      const email = getUserEmail()

      // If the user is not logged in, forcefully redirect the user to logout page.
      if (!email && !isLoginOrResetPage) {
        window.open("/logout", "_self", true)
        failedQueue = []
        return Promise.reject(error.response)
      }

      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({
            resolve,
            reject,
          })
        })
          .then(() => {
            return axiosInstance(originalRequest)
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      }

      originalRequest._retry = true
      isRefreshing = true

      return new Promise(function (resolve, reject) {
        axiosInstance
          .post(
            getServerAPI(EXTEND_API) + "?customerId=" + email,
            {},
            makeConfig({}),
          )
          .then((response) => {
            if (
              !response ||
              response.status === 403 ||
              response.status === 401
            ) {
              window.open("/logout", "_self", true)
              reject()
              return
            }

            processQueue(null, response)
            resolve(axiosInstance(originalRequest))
          })
          .catch(() => {
            window.open("/logout", "_self", true)
            reject()
          })
      })
    } else {
      return Promise.reject(error.response.data)
    }
  },
)

function getServerAPI(url, customRoot) {
  let fullURL =
    customRoot && customRoot !== "" ? customRoot + url : API_ROOT + url
  return fullURL
}

/**
 * https://stackoverflow.com/a/15710692/2805630
 */
function getHash(candidateString) {
  return candidateString.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0)
    return a & a
  }, 0)
}

/**
 * WebAPI to make Asynchronous requests
 * @param url
 * @param config
 * @param onSuccess
 * @param onFailure
 * @param customRoot
 * @returns {Promise}
 */
export const callApi = (
  url,
  requestConfig,
  onSuccess,
  onFailure,
  customRoot,
  enableCancellation = true,
) => {
  const fullURL = getServerAPI(url, customRoot)

  /**
   * Duplicate successive request cancellation logic
   * Cancels the old request with same API signatiure and request type
   *
   * Example:
   * API signature : art-configs/paginated/meta
   * Request type: GET
   */
  const requestUID =
    url.substring(0, url.indexOf("?") > -1 ? url.indexOf("?") : url.length) +
    requestConfig.method

  const hash = getHash(requestUID)
  const controller = new AbortController()
  if (enableCancellation) {
    if (!!cancelTokenStore[hash]) {
      let staleRequestHandler = cancelTokenStore[hash]
      console.debug("Cancelling redundant/stale request")
      staleRequestHandler.abort()
      cancelTokenStore[hash] = controller
    } else {
      cancelTokenStore[hash] = controller
    }
  }

  return new Promise((resolve, reject) => {
    let options = makeConfig(requestConfig)
    axiosInstance
      .request({
        url: fullURL,
        signal: controller.signal,
        ...options,
      })
      .then(({ data, status, statusText }) => {
        //logger.info(statusText, "Response for URL: " + fullURL, data);
        handleSuccess(resolve, onSuccess, data)
      })
      .catch((error) => {
        Logger.error(
          "Error Response: ",
          error,
          "for URL: => " + fullURL,
          " with Request Options: => ",
          options,
        )
        handleFailure(reject, onFailure, error)
      })
      .then(() => {
        //Always || Finally
        /**
         * Cleaning up the cancelTokenStore so the subsequent requests go through.
         */
        if (enableCancellation) delete cancelTokenStore[hash]
      })
  })
}
